import React from 'react';
import {
  Select,
  Empty,
} from 'antd';
import { I18n } from 'react-redux-i18n';

const { Option } = Select;

const AdvancedSelect = (
  {
    value,
    options,
    label,
    onChange,
    disabled,
    placeholder,
    disableSearch,
    onSearch,
    name,
    required,
  },
) => (
  <div className="advanced-select">
    <label className="advanced-select__label">
      <span className="advanced-select__label__inner">
        {label}
      </span>
      <Select
        style={{ width: '100%' }}
        value={value || undefined}
        onChange={onChange}
        optionFilterProp={disableSearch ? 'children' : undefined}
        disabled={disabled || false}
        placeholder={placeholder || I18n.t('shared.selectSomeValue')}
        showSearch={!disableSearch || false}
        allowClear
        onSearch={onSearch ? (val) => onSearch(val) : undefined}
        name={name || undefined}
        required={required || undefined}
        notFoundContent={
          (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={I18n.t('shared.notFoundSearch')}
            />
          )
        }
        filterOption={!!disableSearch ? (
          input,
          option,
        ) => (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        ) : undefined}
      >
        {options && options.length > 0 && options.map((a) => (
          <Option
            key={a.id ? a.id.toString() : 'all'}
            value={a.id}
          >
            {a.name}
          </Option>
        ))}
      </Select>
    </label>
  </div>
);

export default AdvancedSelect;
