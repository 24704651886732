import React from 'react';
import { I18n } from 'react-redux-i18n';
import SectionTitle from '../SectionTitle';
import ScrollAnimation from 'react-animate-on-scroll';

class RobotraderFeaturedFuncSection extends React.PureComponent {
  render() {
    const items = I18n.t('routes.products.robotrader.sections.featuredFuncSection.items');

    return (
      <section className={`page-section page-section__robotrader-feature-func ${this.props.gray ? 'page-section--gray' : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                headline={I18n.t('routes.products.robotrader.sections.featuredFuncSection.title')}
                description={I18n.t('routes.products.robotrader.sections.featuredFuncSection.description')}
              />
            </div>
          </div>
          <div className="page-section__robotrader-feature-func__wrapper">
            <div className="row">
              {items && Object.keys(items).map((item, index) => (
                <div
                  className="col-lg-4"
                  key={index.toString()}
                >
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    duration={0.3}
                  >
                    <div className="page-section__robotrader-feature-func__item">
                      <span className="page-section__robotrader-feature-func__item__circle" />
                      <strong className="page-section__robotrader-feature-func__item__bold">
                        {items[item].bold}
                      </strong>
                      &nbsp;
                      <span className="page-section__robotrader-feature-func__item__regular">
                      {items[item].regular}.
                    </span>
                    </div>
                  </ScrollAnimation>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RobotraderFeaturedFuncSection;
