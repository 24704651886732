import React from 'react';
import {
  Layout,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { PlusCircleOutlined } from '@ant-design/icons';

import PageHeader from '../../components/shared/PageHeader';
import ProductsSection from '../../components/shared/ProductsSection';
import ContactCTASection from '../../components/shared/ContactCTASection';
import FooterSection from '../../components/shared/FooterSection';
import ProductFeatureSection from '../../components/shared/ProductFeatureSection';
import SectionTitle from '../../components/shared/SectionTitle';

class Retail extends React.PureComponent {
  render() {
    const items = I18n.t('routes.retail.sections.products');
    const { Content } = Layout;
    return (
      <Content className="retail__container">
        <PageHeader
          title={I18n.t('routes.retail.header.title')}
          subtitle={I18n.t('routes.retail.header.subtitle')}
          images={[
            '/assets/img/sliders/retail/img_sliders_retail_bg_1.jpg',
          ]}
        />
        <div className="page-section pb-0">
          <SectionTitle
            headline={I18n.t('routes.retail.sections.title')}
            description={I18n.t('routes.retail.sections.subtitle')}
          />
          {items && Object.keys(items).map((item, index) => (
            <ProductFeatureSection
              key={index.toString()}
              imageUrl={items[item].imageUrl}
              title={items[item].title}
              number={items[item].number}
              description={items[item].description}
              reverse={items[item].reverse}
              gray={!!items[item].gray}
              items={items[item].items}
              button={items[item].button.url}
              isInline={!!items[item].inlineItems}
              isTable={!!items[item].table}
              tableItems={!!items[item].table && items[item].table.values}
            />
          ))}
        </div>

        <div className="page-section page-section--gray">
          <SectionTitle
            headline={I18n.t('routes.retail.sections.features.title')}
            description={I18n.t('routes.retail.sections.features.description')}
          />
          <div className="page-section__table-wrapper">
            <ProductFeatureSection
              reverse={!!I18n.t('routes.retail.sections.features.reverse')}
              isTable={!!I18n.t('routes.retail.sections.features.table')}
              tableItems={!!I18n.t('routes.retail.sections.features.table') && I18n.t('routes.retail.sections.features.table.values')}
              tableItemsTwo={!!I18n.t('routes.retail.sections.features.table') && I18n.t('routes.retail.sections.features.table.valuesRowTwo')}
              tableItemsThree={!!I18n.t('routes.retail.sections.features.table') && I18n.t('routes.retail.sections.features.table.valuesRowThree')}
            />
          </div>

          <div className="row text-center">
            <div className="col-lg-12">
              <div className="page-section__altorithm-table__separated">
                <div className="page-section__altorithm-table__separated__icon text-center">
                  <PlusCircleOutlined />
                </div>
                <strong className="page-section__altorithm-table__separated__title">
                  {I18n.t('routes.retail.sections.features.table.valuesRowFourTitle')}
                </strong>
                <ul className="page-section__altorithm-table__separated__items">
                  {Object.values(I18n.t('routes.retail.sections.features.table.valuesRowFour')).map((item, index) => (
                    <li
                      key={index.toString()}
                      className="page-section__altorithm-table__separated__items__single"
                    >
                      <span className="page-section__altorithm-table__separated__items__single__bullet" />
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ProductsSection />
        <ContactCTASection />
        <FooterSection />
      </Content>
    );
  }
}

export default Retail;
