import React from 'react';
import { Carousel } from 'antd';
import { ArrowDown } from 'react-feather';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';

import LargeButton from '../LargeButton';
import AppConfig from '../../../app/config/app.config';

function scrollToContent() {
  const navbarHeight = document.getElementsByClassName('navbar__container')[0].clientHeight;
  const headerHeight = document.getElementsByClassName('page-header')[0].clientHeight;

  window.scrollTo({
    top: headerHeight - navbarHeight,
    behavior: 'smooth',
  });
}

class PageHeader extends React.PureComponent {
  componentDidMount() {
    window.scrollTo({
      top: 0,
    });
  }

  render() {
    const {
      title,
      subtitle,
      images,
      speed,
      effect,
      hideButton,
      smaller,
      paddingBottom,
      transparent,
    } = this.props;
    return (
      <div className="page-header">
        <div className={`page-header__carousel__trap ${smaller ? 'page-header__carousel__trap--smaller' : ''}`} />
        <ScrollAnimation animateIn="fadeIn">
          <Carousel
            className="page-header__carousel"
            autoplay
            autoplaySpeed={speed || AppConfig.headerSliderTimeout}
            effect={effect || 'fade'}
            dots={false}
          >
            {images && images.map((img, index) => (
              <div key={index.toString()}>
                <div
                  className={`page-header__carousel__item ${smaller ? 'page-header__carousel__item--smaller' : ''}`}
                  style={{ backgroundImage: `url(${img})`, opacity: transparent || 1 }}
                />
              </div>
            ))}
          </Carousel>
        </ScrollAnimation>
        <div
          className="page-header__content"
          style={{ paddingBottom }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-header__content__title">
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    duration={0.3}
                  >
                    {title}
                  </ScrollAnimation>
                </div>
                <div className="page-header__content__subtitle">
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    duration={0.3}
                  >
                    {subtitle}
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!hideButton && (
          <div className="page-header__button animated fadeInUp">
            <LargeButton
              text={I18n.t('routes.home.header.buttonText')}
              onClick={() => scrollToContent()}
            />
            <div className="page-header__button__arrow">
              <ArrowDown onClick={() => scrollToContent()} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PageHeader;
