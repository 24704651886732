import React from 'react';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';

class MarketResumeSection extends React.PureComponent {
  render() {
    return (
      <div className="market-resume-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="market-resume-section__title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={0.3}
                >
                  {I18n.t('routes.products.robotrader.sections.marketResume.title')}
                </ScrollAnimation>
              </h1>
              <strong className="market-resume-section__subtitle">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={150}
                  duration={0.3}
                >
                  {I18n.t('routes.products.robotrader.sections.marketResume.subtitle')}
                </ScrollAnimation>
              </strong>
              <ScrollAnimation
                animateIn="fadeInUp"
                delay={300}
                duration={0.3}
              >
                <p className="market-resume-section__item">
                  {I18n.t('routes.products.robotrader.sections.marketResume.items.one')}
                </p>
                <p className="market-resume-section__item">
                  {I18n.t('routes.products.robotrader.sections.marketResume.items.two')}
                </p>
                <p className="market-resume-section__item">
                  {I18n.t('routes.products.robotrader.sections.marketResume.items.three')}
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MarketResumeSection;