import React from 'react';
import { I18n } from 'react-redux-i18n';
import SectionTitle from '../SectionTitle';
import AdvancedButton from '../AdvancedButton';

class AboutSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
    };
  }

  toggleTerms() {
    const { showTerms } = this.state;
    this.setState({ showTerms: !showTerms });
  }

  render() {
    const { showTerms } = this.state;
    return (
      <section className={`page-section page-section__about ${this.props.gray ? 'page-section--gray' : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                headline={I18n.t('routes.about.sections.about.title')}
                description={I18n.t('routes.about.sections.about.description')}
              />

              <p className="text-center">
                <AdvancedButton
                  customClass="page-section__about__terms-url"
                  type="link"
                  text={I18n.t('routes.about.sections.about.terms.title')}
                  onClick={() => this.toggleTerms()}
                />
              </p>

              {showTerms && (
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="page-section__about__terms__values">
                      <ol>
                        <li>O símbolo ® é utilizado ao lado da marca após ter sido certificada como uma marca registrada
                          pelo INPI.
                        </li>
                        <li>A marca registrada RoboTrader® poderá ser utilizada para fazer referência específica ao
                          respectivo produto em materiais, folders, conferências, aplicações, demonstrações, entre
                          outros,
                          desde que acompanhada do símbolo ® e da informação constante no preâmbulo deste documento.
                          <ul>
                            <li>Exemplo de uso incorreto: “RoboTrader Educacional apresenta o inovador curso de
                              Algoritmos Financeiros.”
                            </li>
                            <li>Exemplo de uso correto: “RoboTrader® Educacional apresenta o inovador curso de
                              Algoritmos Financeiros.”
                            </li>
                          </ul>
                        </li>
                        <li>Quando fizer referência a marca registrada RoboTrader®, atribua a posse da marca à BLK
                          Sistemas Financeiros Ltda.,
                          incluindo a seguinte notificação de atribuição de titularidade de marca registrada no local
                          apropriado, seja nos materiais impressos ou online.
                        </li>
                        <ul>
                          <li>Exemplo de uso correto: RoboTrader® é marca registrada de propriedade da BLK Sistemas
                            Financeiros Ltda. O uso indevido da marca RoboTrader®
                            acarretará ao infrator penalidades de acordo com a legislação em vigor.
                          </li>
                        </ul>
                        <li>Toda a utilização da marca RoboTrader® deve ser feita de acordo com estas orientações.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutSection;
