import React from 'react';
import CarouselImg, { Modal, ModalGateway } from 'react-images';
import { Carousel } from 'antd';

import { I18n } from 'react-redux-i18n';
import AdvancedButton from '../AdvancedButton';
import Chevron from '../../../app/svg/ic_chevron-left.svg';

class SectionFeatureSlider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      viewerIsOpen: false,
    };
    this.carouselRef = null;
  }

  openLightbox(index) {
    this.setState({ currentImage: index });
    this.setState({ viewerIsOpen: true });
  }

  closeLightbox() {
    this.setState({ currentImage: 0 });
    this.setState({ viewerIsOpen: false });
  }

  renderCustomModalFooter = ({ currentIndex, views }) => {
    const activeView = currentIndex + 1;
    const totalViews = views.length;

    if (!activeView || !totalViews) return null;
    return (
      <span>
        {activeView} {I18n.t('shared.sections.products.featureSlider.of')} {totalViews}
      </span>
    );
  };

  render() {
    const { viewerIsOpen, currentImage } = this.state;

    const photos = [
      {
        source: '/assets/img/sliders/robotrader/robotrader-principal.png',
        caption: 'Robotrader - Principal',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-plataforma-1.png',
        caption: 'Robotrader - Menu Plataforma',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-plataforma-ordens-1-1.png',
        caption: 'Robotrader - Menu Ordens',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-plataforma-analise-de-mercado-1-2.png',
        caption: 'Robotrader - Menu Análise de mercado',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-indices-de-mercado-1-3.png',
        caption: 'Robotrader - Menu Índices de Mercado',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-algoritmos-2.png',
        caption: 'Robotrader - Menu Algoritmos',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-analise-de-ativos-2-1.png',
        caption: 'Robotrader - Menu Análise de Ativos',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-datatrader-3.png',
        caption: 'Robotrader - Menu Data Trader',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-options-trader-4.png',
        caption: 'Robotrader - Menu Options Trader',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-relatorio-5.png',
        caption: 'Robotrader - Menu Relatório',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-ajustes-6.png',
        caption: 'Robotrader - Menu Ajustes',
      },
      {
        source: '/assets/img/sliders/robotrader/robotrader-menu-status-7.png',
        caption: 'Robotrader - Menu Status',
      },
      // menu end
      {
        source: '/assets/img/sliders/robotrader/analise-grafica.png',
        caption: 'Análise gráfica',
      },
      {
        source: '/assets/img/sliders/robotrader/black-e-scholes.png',
        caption: 'Black & Scholes',
      },
      {
        source: '/assets/img/sliders/robotrader/broker-intervention.png',
        caption: 'Broker Intervention',
      },
      {
        source: '/assets/img/sliders/robotrader/click-n-trade.png',
        caption: 'Click n\' Trade',
      },
      {
        source: '/assets/img/sliders/robotrader/controle-de-acesso.png',
        caption: 'Controle de Acesso',
      },
      {
        source: '/assets/img/sliders/robotrader/data-trader-analisecorretoras.png',
        caption: 'Data Trader - Ranking de Corretoras',
      },
      {
        source: '/assets/img/sliders/robotrader/opt-trader-black-scholes.png',
        caption: 'Options Trader - Black e Scholes',
      },
      {
        source: '/assets/img/sliders/robotrader/variacao-de-volume.png',
        caption: 'Variação de Volume',
      },
    ];

    return (
      <section className={`page-section page-section__feature-slider ${this.props.gray ? 'page-section--gray' : ''}`}>
        <div className="container">
          <div className="page-section__feature-slider__items">
            <div className="row">
              <div className="col-lg-12 position-relative">
                <span className="page-section__feature-slider__items__controls page-section__feature-slider__items__controls--left">
                  <AdvancedButton
                    onClick={() => this.carouselRef.prev()}
                    text={(
                      <img
                        src={Chevron}
                        alt="slider left"
                      />
                    )}
                    type="link"
                  />
                </span>

                <span className="page-section__feature-slider__items__controls page-section__feature-slider__items__controls--right">
                  <AdvancedButton
                    onClick={() => this.carouselRef.next()}
                    text={(
                      <img
                        src={Chevron}
                        alt="slider right"
                      />
                    )}
                    type="link"
                  />
                </span>

                <div className="page-section__feature-slider__items__mask">
                  <img
                    src="/assets/img/img_product_mask.png"
                    alt="product mask"
                  />
                  <div className="page-section__feature-slider__items__mask__inner" />
                </div>

                <div className="page-section__feature-slider__items__carousel">
                  <Carousel
                    dotPosition="bottom"
                    ref={(ref) => { this.carouselRef = ref; }}
                    fade
                  >
                    {photos.map((image, key) => (
                      <div key={key.toString()}>
                        <button
                          type="button"
                          className="page-section__feature-slider__items__single"
                          onClick={() => this.openLightbox(key)}
                        >
                          <img
                            className="page-section__feature-slider__items__single__img"
                            src={image.source}
                            alt={image.caption}
                          />
                        </button>
                        <p className="text-center page-section__feature-slider__items__carousel__phrase">
                          {image.caption}
                        </p>

                      </div>
                    ))}
                  </Carousel>

                </div>

                <ModalGateway>
                  {viewerIsOpen && (
                    <Modal onClose={() => this.closeLightbox()}>
                      <CarouselImg
                        currentIndex={currentImage}
                        views={photos}
                        components={{ FooterCount: this.renderCustomModalFooter }}
                      />
                    </Modal>
                  )}
                </ModalGateway>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SectionFeatureSlider;
