import React from 'react';
import { ChevronRight } from 'react-feather';
import { I18n } from 'react-redux-i18n';
import { Link } from '@reach/router';
import ScrollAnimation from 'react-animate-on-scroll';

import CardWithIcon from '../../../CardWithIcon';
import ProductInfoOne from '../../../../../app/svg/ic_product_robotrader_info_one.svg';
import ProductInfoTwo from '../../../../../app/svg/ic_product_robotrader_info_two.svg';
import ProductInfoThree from '../../../../../app/svg/ic_product_robotrader_info_three.svg';

class ProductRobotrader extends React.PureComponent {
  render() {
    return (
      <div className="page-section__products__single page-section__products__single--robotrader">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <ScrollAnimation animateIn="fadeIn">
                <img
                  className="page-section__products__single__picture img-fluid"
                  src="/assets/img/img_product_robotrader.png"
                  alt="robotrader"
                />
              </ScrollAnimation>
            </div>
            <div className="col-lg-5">
              <ScrollAnimation animateIn="fadeIn">
                {false && (
                  <div className="page-section__products__single__number">
                    1
                  </div>
                )}
                <div className="page-section__products__single__content">
                  <div className="page-section__products__single__content__title">
                    {I18n.t('shared.sections.products.single.robotrader.title')}
                  </div>
                  <div className="page-section__products__single__content__description">
                    {I18n.t('shared.sections.products.single.robotrader.description')}
                  </div>
                  <ul className="page-section__products__single__content__tags">
                    <li className="page-section__products__single__content__tags__single">
                      <Link
                        className="page-section__products__single__content__tags__single__link"
                        to={I18n.t('routes.retail.url')}
                      >
                        # {I18n.t('routes.home.sections.category.cards.one.subtitle')}
                      </Link>
                    </li>
                    <li className="page-section__products__single__content__tags__single">
                      <Link
                        className="page-section__products__single__content__tags__single__link"
                        to={I18n.t('routes.sellside.url')}
                      >
                        # {I18n.t('routes.home.sections.category.cards.two.subtitle')}
                      </Link>
                    </li>
                    <li className="page-section__products__single__content__tags__single">
                      <Link
                        className="page-section__products__single__content__tags__single__link"
                        to={I18n.t('routes.buyside.url')}
                      >
                        # {I18n.t('routes.home.sections.category.cards.three.subtitle')}
                      </Link>
                    </li>
                  </ul>
                  <div>
                    <Link
                      className="page-section__products__single__content__link"
                      to={I18n.t('routes.products.robotrader.url')}
                    >
                      {I18n.t('shared.sections.products.seeMoreButton')}
                      <ChevronRight className="page-section__products__single__content__link__icon" />
                    </Link>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="page-section__products__single__infos">
            <div className="row">
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={ProductInfoOne}
                    title={I18n.t('shared.sections.products.single.robotrader.items.one.title')}
                    description={I18n.t('shared.sections.products.single.robotrader.items.one.description')}
                    left
                  />
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={150}
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={ProductInfoTwo}
                    title={I18n.t('shared.sections.products.single.robotrader.items.two.title')}
                    description={I18n.t('shared.sections.products.single.robotrader.items.two.description')}
                    left
                  />
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={300}
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={ProductInfoThree}
                    title={I18n.t('shared.sections.products.single.robotrader.items.three.title')}
                    description={I18n.t('shared.sections.products.single.robotrader.items.three.description')}
                    left
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductRobotrader;
