import {
  addLoading,
  removeLoading,
} from './loading';
import UserRequests from '../../api/user';

export const ACTION_SAVE_USERS_PAGINATED = 'ACTION_SAVE_USERS_PAGINATED';
export const ACTION_SAVE_USER_DETAILS = 'ACTION_SAVE_USER_DETAILS';
export const ACTION_SAVE_USER_SELECTIVES = 'ACTION_SAVE_USER_SELECTIVES';
export const ACTION_SAVE_USER_SELECTIVES_TWO = 'ACTION_SAVE_USER_SELECTIVES_TWO';

export const ACTION_CLEAN_USER = 'CLEAN_USER';

export const cleanUser = () => ({
  type: ACTION_CLEAN_USER,
  user: null,
});

export const getUsersPaginated = (parameters, me) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const usersPaginated = await UserRequests.get(parameters);
    dispatch({
      type: ACTION_SAVE_USERS_PAGINATED,
      payload: usersPaginated,
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(removeLoading());
  }
};