import React from 'react';
import {
  Layout,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import PageHeader from '../../components/shared/PageHeader';
import ProductsSection from '../../components/shared/ProductsSection';
import ContactCTASection from '../../components/shared/ContactCTASection';
import FooterSection from '../../components/shared/FooterSection';
import AboutSection from '../../components/shared/AboutSection';
// import BrandsSection from '../../components/shared/BrandsSection';

class About extends React.PureComponent {
  render() {
    const { Content } = Layout;
    return (
      <Content className="about__container">
        <PageHeader
          title={I18n.t('routes.about.header.title')}
          subtitle={I18n.t('routes.about.header.subtitle')}
          images={[
            'assets/img/sliders/about/img_sliders_about_bg_1.jpg',
            'assets/img/sliders/about/img_sliders_about_bg_2.jpg',
          ]}
        />
        <AboutSection gray />
        {/* <BrandsSection /> */}
        <ProductsSection />
        <ContactCTASection />
        <FooterSection />
      </Content>
    );
  }
}

export default About;
