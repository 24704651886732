export default {
  application: {
    name: 'BLK',
    shortDescription: 'BLK',
    title: 'Sistemas Financeiros',
    version: {
      title: 'Versão',
      number: '1.0.0',
    },
  },
  navbar: {
    logoAlt: 'BLK - Uma empresa B3',
    menuItems: {
      home: {
        name: 'Home',
      },
      about: {
        name: 'Sobre nós',
      },
      products: {
        name: 'Robotrader',
      },
      algorithms: {
        name: 'Algoritmos',
      },
      support: {
        name: 'Contato',
      },
      privacy: {
        name: 'Política de Privacidade',
        url: '/assets/files/Declaracao_de_Privacidade_de_Dados.pdf?rev=2022.11',
      },
      cookiesPolicy: {
        name: 'Política de Cookies',
        url: '/assets/files/Politica_Cookies_BLK.pdf?rev=2022.11',
      },
      terms: {
        name: 'Termos de Uso',
        url: '/assets/files/Termo_de_Uso_Site_BLK.pdf?rev=2022.11',
      },
      holderRights: {
        name: 'Exercício de Direitos',
        url: 'https://privacyportal.onetrust.com/webform/b0b1f4ef-c7d9-47e5-a1bf-c0bcbbbe1036/1f986f28-6f35-4239-bed2-fc8d1dbaf784',
      },
    },
  },
  shared: {
    notFoundSearch: 'Nada encontrado.',
    selectSomeValue: 'Selecione...',
    typeToContinue: 'Digite algo para pesquisar...',
    yes: 'Sim',
    no: 'Não',
    loading: 'Carregando...',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    sections: {
      footer: {
        menuTitle: 'Institucional',
        menuPhoneTitle: 'Telefones',
        bottomLogoPhrase: 'A BLK Sistemas Financeiros é a empresa líder no setor de algoritmos no Brasil.',
        otherCompaniesLink: 'Empresas do Grupo B3',
      },
      products: {
        title: 'Nossos produtos',
        subtitle: 'Plataforma de ultrabaixa latência e máxima estabilidade, especializada em electronic & algorithmic trading.',
        seeMoreButton: 'saiba mais sobre essa funcionalidade ',
        single: {
          robotrader: {
            url: '/',
            title: 'Plataforma RoboTrader',
            description: 'Uma plataforma de ultrabaixa latência, especializada em electronic trading e monitorada por profissonais altamente qualificados.',
            categoryTags: {
              one: {
                name: 'PESSOA FÍSICA',
              },
              two: {
                name: 'SELL-SIDE',
              },
              three: {
                name: 'BUY-SIDE',
              },
            },
            items: {
              one: {
                title: 'Ambiente especializado',
                description: 'A partir de agora você pode ser ainda mais competitivo, utilizando nossos melhores recursos tecnológicos.',
              },
              two: {
                title: 'Além do Automate',
                description: 'A nova funcionalidade oferece ao usuário a facilidade de acompanhar as informações da tela de "Posição Intraday".',
              },
              three: {
                title: 'HFT (High Frequency Trading)',
                description: 'Integra em uma única plataforma múltiplos Mercados de Capitais, com o objetivo de aumentar a eficiência e versatilidade.',
              },
            },
          },
        },
        featureSlider: {
          of: 'de',
          headline: 'Capturas de tela',
          description: 'Veja algumas imagens dos produtos e módulos (clique na imagem para ver maior)',
          clickDescription: '(clique na imagem para ver maior)',
        },
        productFeature: {
          seeMoreButtonText: 'Saiba mais',
          robotrader: {
            items: {
              one: {
                imageUrl: '/assets/img/product-images/img_product-one.png',
                title: 'Função RTD',
                number: '1',
                description: 'Visualização de cotações pelo Microsoft Excel via RTD',
                reverse: false,
                gray: 'true',
              },
              two: {
                imageUrl: '/assets/img/product-images/img_product-one.png',
                title: 'Análise gráfica',
                number: '2',
                description: 'Visualize através de gráficos precisos e ferramentas',
                reverse: 'true',
                gray: false,
                items: {
                  one: 'Acompanhamento de intraday, diário, semanal e mensal.',
                  two: 'Barra de ferramentas com linhas de tedências, projeções, canais e marcações.',
                },
              },
            },
          },
        },
      },
      testimonials: {
        title: 'O que estão falando da BLK',
        subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam venenatis ipsum dolor, non \nporta sapien cursus eu.',
        uses: 'Utiliza',
        items: [
          {
            id: 0,
            imageUrl: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80',
            name: 'Clayton Soares de Abreu',
            companyName: 'MB Labs',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent molestie, erat condimentum suscipit interdum, sapien orci laoreet nisl.',
            platform: {
              url: '/',
              name: 'Plataforma RoboTrader',
            },
          },
          {
            id: 1,
            imageUrl: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
            name: 'Clayton Soares de Abreu 2',
            companyName: 'MB Labs 2',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent molestie, erat condimentum suscipit interdum, sapien orci laoreet nisl.',
            platform: {
              url: '/',
              name: 'Plataforma RoboTrader',
            },
          },
        ],
      },
      contactCTA: {
        title: 'Gostou? Agende uma demostração!',
        subtitle: 'Nossa equipe de profissionais está altamente capacitada e com muitos anos de experiência no mercado financeiro.',
        buttonText: 'Fale com nosso especialista',
        buttonLink: '/',
      },
    },
  },
  routes: {
    home: {
      url: '/',
      pageTitle: 'Sistemas Financeiros',
      header: {
        title: 'Líder no setor de algoritmos financeiros\nprodutos com baixa latência e estabilidade',
        subtitle: 'A BLK Sistemas Financeiros é a empresa especializada em electronic & algorithmic trading, com ênfase na criação e desenvolvimento de\nsoftwares e algoritmos de alta frequência para o mercado de capitais e derivativos financeiros.\n\n Em março de 2019 foi realizada a aquisição de 75% do capital da BLK Sistemas Financeiros Ltda pela B3 S.A. com essa aquisição estreitamos\n relacionamento alinhados à estratégia da B3 para oferecer soluções mais completas e expansão do portfólio de produtos.\nEm setembro de 2020, foi realizada a aquisição dos outros 25% pela B3 S.A.',
        buttonText: 'Saiba mais',
      },
      sections: {
        category: {
          headline: 'A melhor solução\npara você ou seu negócio',
          description: 'A BLK possui soluções para Gerenciamento de ordens administradas (algoritmos), que permite a\nconexão nacional e internacional entre corretora (sell side) e seus clientes (buy side).',
          cards: {
            buttonText: 'Veja os produtos',
            one: {
              title: 'Varejo',
              subtitle: 'Pessoa física',
              description: 'Voltado para autônomos que buscam uma plataforma para envio e acompanhamento de ordens.\n\nOferecemos um sistema de fácil uso e aprendizado acelerado, com ótima usabilidade e relatórios avançados.',
            },
            two: {
              title: 'Corretoras e bancos',
              subtitle: 'Sell-Side',
              description: 'Oferecemos uma plataforma de negociação com acesso direto ao mercado, sistema além de confiável, completo e de última geração.\nEstratégias de negociação avançadas com mecanismo de ordem algorítmicas e negociações complexas de opções.',
            },
            three: {
              title: 'Gestores de fundos',
              subtitle: 'Buy-Side',
              description: 'Tecnologias construídas para suportar os mercados de alto volume e baixa latência, com soluções mais recentes de protocolo FIX, oferecendo muito mais estabilidade e agilidade no envio da ordem.\nNa modalidade Single Broker e Multi Broker',
            },
          },
        },
        strategy: {
          cards: {
            one: {
              title: 'Missão',
              subtitle: 'Onde queremos chegar',
              description: 'Trabalhamos para conectar, desenvolver e viabilizar o mercado financeiro e de capitais. Junto  com nossos clientes e a sociedade, potencializamos o crescimento do Brasil.',
            },
            three: {
              title: 'Valores',
              subtitle: 'O que valorizamos',
              descriptionOne: 'Atitude correta para hoje, amanhã e sempre',
              descriptionTwo: 'Abertura e colaboração',
              descriptionThree: 'Proximidade e satisfação do cliente',
              descriptionFour: 'Solidez e credibilidade operacional',
              descriptionFive: 'Ambiente para as pessoas se desenvolverem.',
            },
          },
        },
      },
    },
    about: {
      url: '/quem-somos',
      pageTitle: 'Quem somos',
      header: {
        title: 'Líder no setor de algoritmos financeiros\nprodutos com baixa latência e estabilidade',
        subtitle: 'Especializada em electronic & algorithmic trading, com ênfase na criação e\ndesenvolvimento de softwares e algoritmos.',
        buttonText: 'Saiba mais',
      },
      sections: {
        about: {
          title: 'Sobre a BLK',
          description: 'A BLK Sistemas Financeiros é a empresa líder no setor de algoritmos no Brasil, especializada em electronic & algorithmic trading, com ênfase na criação e desenvolvimento de softwares e algoritmos de alta frequência para o mercado de capitais e derivativos financeiros.\n\nNossa equipe de profissionais está altamente capacitada e com muitos anos de experiência no mercado financeiro, o que inclui profundo conhecimento da engenharia operacional nos mercados de ações, futuros, renda fixa, commodities, seus derivativos e modelos de precificação.\n\nA empresa possui sede própria, localizada no Centro de São Paulo, a poucos metros da Bolsa de Valores. Dispomos de gerador de energia e acesso liberado 24 horas por dia, sete dias por semana, o que nos garante total segurança na manutenção e funcionalidade de nossos sistemas e serviços oferecidos.\n',
          terms: {
            title: 'Termos de uso da Marca Registrada RoboTrader®',
            url: '/',
          },
        },
        brands: {
          title: 'Empresas que usam e confiam',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam venenatis ipsum dolor, non \nporta sapien cursus eu.',
        },
      },
    },
    products: {
      robotrader: {
        url: '/produtos/robotrader',
        pageTitle: 'Plataforma Robotrader',
        header: {
          title: 'Plataforma Robotrader',
          subtitle: 'Uma plataforma de ultrabaixa latência, especializada em eletronic  \ntrading e monitorada por profissionais altamente qualificados.',
          buttonText: 'Saiba mais',
        },
        sections: {
          aboutSection: {
            title: 'A plataforma RoboTrader',
            description: 'Plataforma de ultrabaixa latência e máxima estabilidade, especializada em electronic & algorithmic trading.\n\nA mais moderna e completa ferramenta do mercado, garantindo aos usuários os mais avançados recursos para efetuarem suas operações com precisão, rapidez e segurança.',
            items: {
              one: 'Algorítimos\npré-customizados',
              two: 'Plataforma\nMulti-broker',
              three: 'ClickTrade \nordens instantâneas',
            },
          },
          featuredFuncSection: {
            title: 'Principais funções',
            description: 'Veja abaixo as principais funções e vantagens da Plataforma RoboTrader.',
            items: {
              one: {
                bold: 'Telas simples',
                regular: 'e customizadas',
              },
              two: {
                bold: 'Cotações',
                regular: 'nacionais e internacionais',
              },
              three: {
                bold: 'DataTrader,',
                regular: 'raio-x do mercado',
              },
              four: {
                bold: 'Fat-Finger,',
                regular: 'controle de limites',
              },
              five: {
                bold: 'Options Trader,',
                regular: 'simulador de operações',
              },
              six: {
                bold: 'P&L Intraday,',
                regular: 'em tempo real',
              },
            },
          },
          marketResume: {
            title: 'Resumo de mercado',
            subtitle: 'com cotações nacionais e internacionais',
            items: {
              one: 'Bovespa, BM&F, íncicies mundiais (Dow Jones, S&P, CBOE, NYSE, NASDAQ)',
              two: 'U.S. Equities (NYSE, NASDAQ, AMEX, OTCBB), moedas (CME e Forex),',
              three: 'Commodities (BTOT, COMEX, NYMEX, NYBOT), NYSE Equities e ADRs',
            },
          },
          robotraderFunc: {
            one: {
              title: 'Book de ofertas',
              subtitle: 'Vários tipos de visualizações com book de ofertas:',
              items: {
                one: 'Completo',
                two: 'Preço consolidado',
                three: 'Preço médio',
                four: 'Profundidade',
                five: 'Acompanhamento de corretoras selecionadas',
              },
            },
            two: {
              title: 'Basket',
              subtitle: 'Diversos estilos de execução:',
              items: {
                one: 'BestOffer',
                two: 'Mesmo lado',
                three: 'Último',
                four: 'Fixo',
                five: 'Ghost',
                six: 'Leilão aberto',
                seven: 'Compra',
                eight: 'Leilão fechado',
                nine: 'Venda',
                ten: 'Mercado proteção',
                eleven: 'Contra parte',
                twelve: 'MOC',
              },
            },
          },
          brands: {
            title: 'Empresas que usam e confiam',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam venenatis ipsum dolor, non \nporta sapien cursus eu.',
          },
        },
        algorithms: {
          url: '/produtos/robotrader/algoritmos',
          pageTitle: 'Algoritmos da Plataforma Robotrader',
          header: {
            title: 'Algoritmos',
            subtitle: 'O software RoboTrader Algoritmos foi desenvolvido pela BLK Sistemas Financeiros,\nempresa 100% brasileira e líder no segmento de algoritmos financeiros no Brasil.',
            buttonText: 'Saiba mais',
          },
          sections: {
            table: {
              title: 'Algoritmos Robotrader',
              subtitle: 'O aplicativo oferece a seus usuários algoritmos pré-customizados, que executam ordens com baixa latência e alta confiabilidade, para os segmentos Bovespa e BM&F, atendendo as diferentes necessidades de cada cliente.',
              contractMessage: '* Contratados separadamente',
              data: {
                headers: {
                  one: 'BOVESPA',
                  two: 'BM&F',
                },
                values: {
                  1: {
                    value: 'Diferencial de preço',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  2: {
                    value: 'Diferencial financeiro',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  3: {
                    value: 'Financeiro',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  4: {
                    value: 'Ratio de quantidade',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  5: {
                    value: 'Ratio financeiro',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  6: {
                    value: 'Multileg',
                    checkOne: 'true',
                    checkTwo: false,
                  },
                  7: {
                    value: 'Volatilidade',
                    checkOne: 'true',
                    checkTwo: false,
                  },
                  8: {
                    value: 'Skew de volatilidade',
                    checkOne: 'true',
                    checkTwo: false,
                  },
                  9: {
                    value: 'Basket',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  10: {
                    value: 'Best offer',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  11: {
                    value: 'Ghost',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  12: {
                    value: 'Best offer / Ghost',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  13: {
                    value: 'TWAP',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                },

                valuesTwo: {
                  1: {
                    value: 'VWAP',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  2: {
                    value: 'Participativa',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  3: {
                    value: 'Volatilidade IBOV*',
                    checkOne: 'true',
                  },
                  4: {
                    value: 'Skew de volatilidade IBOV*',
                    checkOne: 'true',
                  },
                  5: {
                    value: 'Cash & Carry*',
                    checkOne: 'true',
                  },
                  6: {
                    value: 'MultiMercado* (Arbitragem BOVESPA x BM&F)',
                    checkOne: 'true',
                  },
                  7: {
                    value: 'Forward',
                    checkTwo: 'true',
                  },
                  8: {
                    value: 'Duration',
                    checkTwo: 'true',
                  },
                  9: {
                    value: 'Rolagem',
                    checkTwo: 'true',
                  },
                  10: {
                    value: 'FRA de DI',
                    checkTwo: 'true',
                  },
                  11: {
                    value: 'Condor de DI',
                    checkTwo: 'true',
                  },
                  12: {
                    value: 'Butterfly de DI',
                    checkTwo: 'true',
                  },
                  13: {
                    value: 'Market Maker*',
                    checkOne: 'true',
                    checkTwo: 'true',
                  },
                  14: {
                    value: 'Spread ETF',
                    checkOne: 'true',
                  },
                },

              },
            },
            features: {
              1: {
                number: '1',
                imageUrl: '/assets/img/product-images/img_product-one.png',
                title: 'Automate',
                description: 'Solução perfeita para quem quer desenvolver suas próprias estratégias sem abrir mão da agilidade e precisão da automatização. Com programação simples, baseada na interface Microsoft Excel, é possível rapidamente criar e testar os seus algoritmos em tempo real.',
                reverse: false,
                gray: false,
                items: {
                  1: 'Resumo de mercado: último, ofertas, máximo, mínimo, entre outros.',
                  2: 'Book de ofertas completo e agregado.',
                  3: 'Taxa de juros: período, over e ano 252 dias.',
                  4: 'Black & Scholes: volatilidade e gregas.',
                  5: 'Ordens de compra e venda simples.',
                  6: 'Barra de ferramentas com linhas de tedências, projeções, canais e marcações.',
                },
              },
              2: {
                number: '2',
                imageUrl: '/assets/img/img_collate.png',
                title: 'Colocation',
                description: 'A BLK Sistemas Financeiros criou uma solução tecnológica para que seus algoritmos sejam hospedados em nossos servidores, sem que você precise se preocupar com questões de infraestrutura, escalabilidade e segurança. \n\nO Colocation da BLK garante:',
                reverse: 'true',
                gray: 'true',
                items: {
                  1: 'Baixa latência.',
                  2: 'Alta disponibilidade.',
                },
              },
            },
          },
        },
      },
    },
    retail: {
      url: '/produtos/pessoa-fisica',
      pageTitle: 'Produtos voltados para pessoa física',
      header: {
        title: 'Pessoa física',
        subtitle: 'Voltado para autônomos que buscam uma plataforma para envio e acompanhamento de ordens.',
        buttonText: 'Saiba mais',
      },
      sections: {
        title: 'Produtos voltados para pessoa física',
        subtitle: 'Oferecemos um sistema de fácil uso e aprendizado acelerado, com ótima usabilidade e relatórios avançados. Veja abaixo as principais funções.',
        products: {
          1: {
            number: '1',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Click&Trade',
            description: 'Plataforma de ultrabaixa latência e máxima estabilidade, especializada em electronic trading. A mais moderna e completa ferramenta do mercado, garantindo aos usuários os mais avançados recursos para efetuarem suas operações com precisão, rapidez e segurança.',
            reverse: false,
            gray: false,
            button: {
              url: '/produtos/produto-x',
            },
          },
          2: {
            number: '2',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Data Trader',
            description: 'Ferramenta que permite a análise e acompanhamento dos negócios realizados, em tempo real, permitindo uma rápida tomada de decisão.\n\nDentre os relatórios disponibilizados, estão:',
            reverse: 'true',
            gray: 'true',
            button: {
              url: '/produtos/produto-x',
            },
            inlineItems: 'y',
            items: {
              1: 'Ranking de corretoras',
              2: 'Opções em aberto',
              3: 'Resumo de negócios',
              4: 'Ranking de ativos',
              5: 'Variações de volume',
              6: 'Histórico de ativo vs. corretora',
              7: 'Empréstimos de Ações',
              8: 'Negócios em Destaque',
              9: 'Negócios Cancelados',
              10: 'Resumo do Ativo',
              11: 'Histórico Times & Trades',
            },
          },
          3: {
            number: '3',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Options Trader',
            description: 'Ferramenta que permite a análise e acompanhamento de diversas estratégias financeiras direcionadas ao mercado BM&F e BOVESPA, fornecendo em tempo real análises e principalmente cenários do mercado de opções. Dentre os módulos disponibilizados, possui:',
            reverse: false,
            gray: false,
            button: {
              url: '/produtos/produto-x',
            },
            inlineItems: 'y',
            items: {
              1: 'Termo de ações',
              2: 'Termo Curva de Juros',
              3: 'Black & Scholes',
              4: 'Simulador gráfico',
              5: 'Box de ouro',
              6: 'Ação + Call + Put',
              7: 'Box Spread',
              8: 'Multicálculo',
            },
          },
        },
        features: {
          title: 'Versões para todos os perfís',
          description: 'Disponibilizamos algoritmos pré-customizados que executam ordens com baixa latência e alta confiabilidade. ',
          button: {
            url: '/produtos/produto-x',
          },
          table: {
            headers: {
              one: 'RT Click&Trade',
              two: 'Algo Lite',
              three: 'Algo Pro',
              four: 'Assessor Pro',
              five: 'RT Plataforma',
            },
            values: {
              0: {
                value: 'Basket',
                checkOne: 'true',
                checkTwo: 'true',
                checkThree: 'true',
                checkFour: 'true',
                checkFive: 'true',
              },
              1: {
                value: 'Diferencial de preço',
                checkOne: false,
                checkTwo: 'true',
                checkThree: 'true',
                checkFour: 'true',
                checkFive: false,
              },
              2: {
                value: 'Diferencial financeiro',
                checkOne: false,
                checkTwo: 'true',
                checkThree: 'true',
                checkFour: 'true',
                checkFive: false,
              },
              3: {
                value: 'Financeiro',
                checkOne: false,
                checkTwo: 'true',
                checkThree: 'true',
                checkFour: 'true',
                checkFive: false,
              },
              4: {
                value: 'Ratio de quantidade',
                checkOne: false,
                checkTwo: 'true',
                checkThree: 'true',
                checkFour: 'true',
                checkFive: false,
              },
              5: {
                value: 'Ratio financeiro',
                checkOne: false,
                checkTwo: 'true',
                checkThree: 'true',
                checkFour: 'true',
                checkFive: false,
              },
              6: {
                value: 'Volatilidade',
                checkOne: false,
                checkTwo: false,
                checkThree: 'true',
                checkFour: 'true',
                checkFive: false,
              },
              7: {
                value: 'Skew de volatilidade',
                checkOne: false,
                checkTwo: false,
                checkThree: 'true',
                checkFour: 'true',
                checkFive: false,
              },
              8: {
                value: 'Multileg',
                checkOne: false,
                checkTwo: false,
                checkThree: 'true',
                checkFour: 'true',
                checkFive: false,
              },
            },

            valuesRowTwoTitle: 'Funcionalidades dos algorítmos',
            valuesRowTwo: {
              0: {
                value: 'Algos simultâneos',
                valueOne: '50 baskets',
                valueTwo: '5 algos',
                valueThree: '20 algos',
                valueFour: '50 algos',
                valueFive: '100 baskets',
              },
              1: {
                value: 'Nº de tranches',
                valueOne: '-',
                valueTwo: '2 tranches',
                valueThree: '10 tranches',
                valueFour: '10 tranches',
                valueFive: '-',
              },
              2: {
                value: 'Nº de contas',
                valueOne: '1 conta',
                valueTwo: '1 conta',
                valueThree: '10 contas',
                valueFour: '100 contas',
                valueFive: '100 contas',
              },
              3: {
                value: 'Segmento Bovespa',
                valueOne: '-',
                valueTwo: '-',
                valueThree: '-',
                valueFour: '-',
                valueFive: '-',
              },
              4: {
                value: 'Segmento BM&F',
                valueOne: '-',
                valueTwo: '-',
                valueThree: '-',
                valueFour: '-',
                valueFive: '-',
              },
            },

            valuesRowThreeTitle: 'Funcionalidades da plataforma',
            valuesRowThree: {
              1: {
                value: 'Qtde de Books',
                valueOne: '3 telas',
                valueTwo: '3 telas',
                valueThree: '3 telas',
                valueFour: '3 telas',
                valueFive: '5 telas',
              },
              2: {
                value: 'Qtde de Resumo de Mercado',
                valueOne: '1 tela',
                valueTwo: '1 tela',
                valueThree: '1 tela',
                valueFour: '1 tela',
                valueFive: '5 telas',
              },
              3: {
                value: 'Detalhes do Ativo (F3)',
                valueOne: '1 tela',
                valueTwo: '1 tela',
                valueThree: '1 tela',
                valueFour: '1 tela',
                valueFive: '5 telas',
              },
              4: {
                value: 'Gráfico',
                valueOne: '3 telas',
                valueTwo: '3 telas',
                valueThree: '3 telas',
                valueFour: '3 telas',
                valueFive: '5 telas',
              },
              5: {
                value: 'Times & Trades',
                valueOne: '3 telas',
                valueTwo: '3 telas',
                valueThree: '3 telas',
                valueFour: '3 telas',
                valueFive: '5 telas',
              },
              6: {
                value: 'Boleta Click&Trade',
                valueOne: 'Sim',
                valueTwo: 'Sim',
                valueThree: 'Sim',
                valueFour: 'Sim',
                valueFive: 'Sim',
              },
              7: {
                value: 'Market data via RTD',
                valueOne: 'Sim',
                valueTwo: 'Sim',
                valueThree: 'Sim',
                valueFour: 'Sim',
                valueFive: 'Sim',
              },
              8: {
                value: 'News',
                valueOne: 'Sim',
                valueTwo: 'Sim',
                valueThree: 'Sim',
                valueFour: 'Sim',
                valueFive: 'Sim',
              },
              9: {
                value: 'Análises de Mercado',
                valueOne: 'Não',
                valueTwo: 'Não',
                valueThree: 'Não',
                valueFour: 'Não',
                valueFive: 'Sim',
              },
              10: {
                value: 'Índices de Mercado',
                valueOne: 'Não',
                valueTwo: 'Não',
                valueThree: 'Não',
                valueFour: 'Não',
                valueFive: 'Sim',
              },
              11: {
                value: 'Ticker',
                valueOne: 'Sim',
                valueTwo: 'Sim',
                valueThree: 'Sim',
                valueFour: 'Sim',
                valueFive: 'Sim',
              },
              12: {
                value: 'Instrumentos em Leilão',
                valueOne: 'Sim',
                valueTwo: 'Sim',
                valueThree: 'Sim',
                valueFour: 'Sim',
                valueFive: 'Sim',
              },
            },

            valuesRowFourTitle: 'Contratados separadamente',
            valuesRowFour: {
              one: {
                title: 'DataTrader',
              },
              two: {
                title: 'Options Trader',
              },
              three: {
                title: 'DataTrader + Options Trader',
              },
            },
          },
        },
      },
    },
    sellside: {
      url: '/produtos/sell-side',
      pageTitle: 'Sell-Side',
      header: {
        title: 'Sell-Side',
        subtitle: 'Estratégias de negociação avançadas com mecanismo de ordem algorítmicas e negociações complexas de opções.',
        buttonText: 'Saiba mais',
      },
      sections: {
        title: 'Produtos voltados para Sell-Side',
        subtitle: 'Oferecemos uma plataforma de negociação com acesso direto ao mercado, sistema além de confiável, completo e de última geração.',
        products: {
          1: {
            number: '1',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Cash&Carry',
            description: 'Módulo que permite a execução da operação de cash&carry, que consiste na compra de uma cesta de ativos no mercado a vista e venda simultânea do índice futuro.',
            reverse: false,
            gray: false,
            button: {
              url: '/produtos/produto-x',
            },
          },
          2: {
            number: '2',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Market Maker',
            description: 'Módulo que permite o participante do mercado manter ofertas de compra e venda de um ativo dando liquidez a ele. Em geral, utilizado por bancos, corretoras e demais instituições financeiras contratadas para essa finalidade.',
            reverse: 'true',
            gray: 'true',
            button: {
              url: '/produtos/produto-x',
            },
          },
          3: {
            number: '3',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Automate',
            description: 'Solução perfeita para quem quer desenvolver suas próprias estratégias sem abrir mão da agilidade e precisão da automatização. Com programação simples, baseada na interface Microsoft Excel, é possível rapidamente criar e testar os seus algoritmos em tempo real.',
            reverse: false,
            gray: false,
            button: {
              url: '/produtos/produto-x',
            },
          },
        },
      },
    },
    buyside: {
      url: '/produtos/buy-side',
      pageTitle: 'Buy-Side',
      header: {
        title: 'Buy-Side',
        subtitle: 'Tecnologias construídas para suportar os mercados de alto volume e baixa latência, com soluções mais recentes de protocolo FIX, oferecendo muito mais estabilidade e agilidade no envio da ordem.\nNa modalidade Single Broker e Multi Broker.',
        buttonText: 'Saiba mais',
      },
      sections: {
        title: 'Produtos voltados pra Buy-Side',
        subtitle: 'Veja abaixo os produtos ideais para o seu negócio.',
        products: {
          1: {
            number: '1',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Cash&Carry',
            description: 'Módulo que permite a execução da operação de cash&carry, que consiste na compra de uma cesta de ativos no mercado a vista e venda simultânea do índice futuro.',
            reverse: false,
            gray: false,
            button: {
              url: '/produtos/produto-x',
            },
          },
          2: {
            number: '2',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Market Maker',
            description: 'Módulo que permite o participante do mercado manter ofertas de compra e venda de um ativo dando liquidez a ele. Em geral, utilizado por bancos, corretoras e demais instituições financeiras contratadas para essa finalidade.',
            reverse: 'true',
            gray: 'true',
            button: {
              url: '/produtos/produto-x',
            },
          },
          3: {
            number: '3',
            imageUrl: '/assets/img/product-images/img_product-one.png',
            title: 'Automate',
            description: 'Solução perfeita para quem quer desenvolver suas próprias estratégias sem abrir mão da agilidade e precisão da automatização. Com programação simples, baseada na interface Microsoft Excel, é possível rapidamente criar e testar os seus algoritmos em tempo real.',
            reverse: false,
            gray: false,
            button: {
              url: '/produtos/produto-x',
            },
          },
        },
      },
    },

    contact: {
      url: '/contato',
      pageTitle: 'Fale conosco',
      header: {
        title: 'Fale conosco',
        subtitle: 'Alguma dúvida ou quer saber mais sobre a plataforma?\nEscolha um dos canais abaixo ou entre em contato através do formulário.',
      },
      thanks: '/obrigado',
      sections: {
        infos: {
          one: {
            title: 'Comercial',
            subtitle: 'Telefone / E-mail',
            description: 'Entre em contato conosco para saber mais sobre o que oferecemos, funcionalidades de nosso sistema e solicitar uma versão de testes da plataforma.',
            infoOne: '+55 (11) 3295-1522',
            infoOneClean: '+551132951522',
            infoTwo: 'comercial@blk.com.br',
          },
          two: {
            title: 'Suporte ao cliente',
            subtitle: 'Telefone / E-mail',
            description: 'Para completar a qualidade dos nossos serviços, temos uma equipe de suporte altamente qualificada para auxiliar em qualquer ocasião os usuários dos produtos da BLK.',
            infoOne: '+55 (11) 3295-1520',
            infoOneClean: '+551132951520',
            infoTwo: 'suporte@blk.com.br',
          },
        },
        form: {
          acceptedTerms: 'Eu concordo com as condições previstas na <a href="%{linkPrivacy}" target="_blank" rel="noopener">%{textPrivacy}</a> e estou ciente de que os dados informados serão utilizados para prestar retorno a mensagem registrada neste canal.',
          optIn: 'Eu aceito receber comunicações em meu e-mail de caráter publicitário sobre os Produtos.',
          optInInfo: 'Você poderá solicitar o cancelamento das nossas comunicações quando quiser, utilizando o opt-out existente nas mensagens encaminhadas por nós. Para maiores informações, consulte a nossa Política de Privacidade.',
          address: {
            title: 'Endereço',
            info: 'Avenida Presidente Juscelino Kubitschek, nº 1.327\n2º andar, Sala 22 - CEP: 04543-011\nSão Paulo - SP',
          },
          title: 'Deixe-nos uma mensagem',
          inputs: {
            one: {
              label: 'Nome',
              placeholder: 'Seu nome',
            },
            two: {
              label: 'E-mail',
              placeholder: 'Seu e-mail válido',
            },
            three: {
              label: 'Telefone para contato',
              mask: '99 9.9999.9999',
            },
            four: {
              label: 'Assunto',
              placeholder: 'Nos diga o motivo da mensagem',
              options: {
                1: {
                  id: 'Contratação',
                  name: 'Contratação',
                },
                2: {
                  id: 'Informação',
                  name: 'Informação',
                },
                3: {
                  id: 'Elogios',
                  name: 'Elogios',
                },
                4: {
                  id: 'Produtos',
                  name: 'Produtos',
                },
                5: {
                  id: 'Sugestões',
                  name: 'Sugestões',
                },
                6: {
                  id: 'Inscrição na Newsletter BLK',
                  name: 'Inscrição na Newsletter BLK',
                },
                7: {
                  id: 'Outros assuntos',
                  name: 'Outros assuntos',
                },
              },
            },
            five: {
              label: 'Como conheceu a BLK?',
              placeholder: 'Selecione...',
              options: {
                1: {
                  id: 'LinkedIn',
                  name: 'LinkedIn',
                },
                2: {
                  id: 'Google',
                  name: 'Google',
                },
                3: {
                  id: 'B3',
                  name: 'B3',
                },
                4: {
                  id: 'Indicação',
                  name: 'Indicação',
                },
                5: {
                  id: 'Outros',
                  name: 'Outros',
                },
              },
            },
            six: {
              label: 'Mensagem',
              placeholder: 'Digite sua mensagem',
            },
            seven: {
              label: 'Sobrenome',
              placeholder: 'Seu sobrenome',
            },
            eight: {
              label: 'Qual?',
              placeholder: 'Digite por onde conheceu',
            },
            nine: {
              label: 'Qual?',
              placeholder: 'Digite aqui',
            }
          },
          sendBtnText: 'Enviar mensagem',
        },
      },
    },
  },
  enum: {
    contactTime: {
      levelOne: 'Nível 1 - 0 a 30 segundos - passagem ',
      levelTwo: 'Nível 2 - 0,5 a 2 minutos - contato rapido ',
      levelThree: 'Nível 3 - 2 min a 5 min - contato moderado ',
      levelFour: 'Nível 4 - +5min - alto contato',
    },
  },
};
