import React from 'react';
import {
  Checkbox,
} from 'antd';

const AdvancedCheckbox = ({
  label,
  onChange,
  value,
  disabled,
  noPadding,
}) => (
  <div
    className="advanced-checkbox"
    style={{
      paddingBottom: noPadding ? 0 : undefined,
    }}
  >
    <Checkbox
      onChange={(e) => onChange(e.target.checked)}
      defaultChecked={!!value}
      disabled={disabled || false}
      checked={!!value ? true : false}
    >
      <span className="advanced-checkbox__label__inner">
        {label}
      </span>
    </Checkbox>
  </div>
);

export default AdvancedCheckbox;
