import React from 'react';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';
import { ArrowRight } from 'react-feather';
import LargeButton from '../LargeButton';

class ContactCTASection extends React.PureComponent {
  render() {
    return (
      <div className="contact-cta-section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="contact-cta-section__title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={0.3}
                >
                  {I18n.t('shared.sections.contactCTA.title')}
                </ScrollAnimation>
              </div>
              <div className="contact-cta-section__subtitle">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={150}
                  duration={0.3}
                >
                  {I18n.t('shared.sections.contactCTA.subtitle')}
                </ScrollAnimation>
              </div>
              <div className="contact-cta-section__button">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={300}
                  duration={0.3}
                >
                  <LargeButton
                    text={I18n.t('shared.sections.contactCTA.buttonText')}
                    icon={<ArrowRight />}
                    href={I18n.t('routes.contact.url')}
                    blueBackground
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactCTASection;