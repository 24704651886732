import React from 'react';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';
import { Divider } from 'antd';
import { ArrowRight } from 'react-feather';

import LargeButton from '../LargeButton';
import TableFeatureCheck from '../../../app/svg/ic_table_feature.svg';
import TableFeatureOff from '../../../app/svg/ic_table_feature_off.svg';

class ProductFeatureSection extends React.PureComponent {
  render() {
    const {
      gray,
      reverse,
      imageUrl,
      title,
      number,
      description,
      items,
      button,
      isInline,
      isTable,
      tableItems,
      tableItemsTwo,
      tableItemsThree,
    } = this.props;

    return (
      <section className={`page-section page-section__product-feature ${gray ? 'page-section--gray' : ''}`}>
        <ScrollAnimation animateIn="fadeIn">
          <div className="container">
            <div className={`row row-eq-height ${reverse ? 'd-flex flex-row-reverse' : ''}`}>
              <div className={`col-lg-${isTable ? '12' : '6'} text-center`}>
                {isTable ? (
                  <div className="page-section__altorithm-table__content-wrapper page-section__product-feature__content-wrapper">
                    <div className="row">
                      <div className="col-2 offset-2 text-center p-0">
                        <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--left">
                          {I18n.t('routes.retail.sections.features.table.headers.one')}
                        </div>
                      </div>
                      <div className="col-2 text-center p-0">
                        <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                          {I18n.t('routes.retail.sections.features.table.headers.two')}
                        </div>
                      </div>
                      <div className="col-2 text-center p-0">
                        <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                          {I18n.t('routes.retail.sections.features.table.headers.three')}
                        </div>
                      </div>
                      <div className="col-2 text-center p-0">
                        <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                          {I18n.t('routes.retail.sections.features.table.headers.four')}
                        </div>
                      </div>
                      <div className="col-2 text-center p-0">
                        <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--right">
                          {I18n.t('routes.retail.sections.features.table.headers.five')}
                        </div>
                      </div>
                    </div>

                    <div className="page-section__altorithm-table__item__wrapper">
                      {tableItems && Object.keys(tableItems).map((item, index) => (
                        <div key={index.toString()}>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-2 p-0">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItems[item].value}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItems[item].checkOne ? (
                                      <img
                                        src={TableFeatureCheck}
                                        alt="feature check one"
                                      />
                                    ) : (
                                      <img
                                        src={TableFeatureOff}
                                        alt="feature off one"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItems[item].checkTwo ? (
                                      <img
                                        src={TableFeatureCheck}
                                        alt="feature check two"
                                      />
                                    ) : (
                                      <img
                                        src={TableFeatureOff}
                                        alt="feature off two"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItems[item].checkThree ? (
                                      <img
                                        src={TableFeatureCheck}
                                        alt="feature check two"
                                      />
                                    ) : (
                                      <img
                                        src={TableFeatureOff}
                                        alt="feature off two"
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItems[item].checkFour ? (
                                      <img
                                        src={TableFeatureCheck}
                                        alt="feature check two"
                                      />
                                    ) : (
                                      <img
                                        src={TableFeatureOff}
                                        alt="feature off two"
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItems[item].checkFive ? (
                                      <img
                                        src={TableFeatureCheck}
                                        alt="feature check two"
                                      />
                                    ) : (
                                      <img
                                        src={TableFeatureOff}
                                        alt="feature off two"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="page-section__altorithm-table__fix">
                        <Divider />
                        <div className="row">
                          <div className="col-12">
                            <strong className="page-section__altorithm-table__title">
                              {I18n.t('routes.retail.sections.features.table.valuesRowTwoTitle')}
                            </strong>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-2 offset-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--left">
                            {I18n.t('routes.retail.sections.features.table.headers.one')}
                          </div>
                        </div>
                        <div className="col-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                            {I18n.t('routes.retail.sections.features.table.headers.two')}
                          </div>
                        </div>
                        <div className="col-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                            {I18n.t('routes.retail.sections.features.table.headers.three')}
                          </div>
                        </div>
                        <div className="col-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                            {I18n.t('routes.retail.sections.features.table.headers.four')}
                          </div>
                        </div>
                        <div className="col-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--right">
                            {I18n.t('routes.retail.sections.features.table.headers.five')}
                          </div>
                        </div>
                      </div>
                      {tableItemsTwo && Object.keys(tableItemsTwo).map((itemTwo, index) => (
                        <div key={index.toString()}>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-2 p-0">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsTwo[itemTwo].value}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsTwo[itemTwo].valueOne}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsTwo[itemTwo].valueTwo}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsTwo[itemTwo].valueThree}
                                  </div>
                                </div>

                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsTwo[itemTwo].valueFour}
                                  </div>
                                </div>

                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsTwo[itemTwo].valueFive}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="page-section__altorithm-table__fix">
                        <Divider />
                        <div className="row">
                          <div className="col-12">
                            <strong className="page-section__altorithm-table__title">
                              {I18n.t('routes.retail.sections.features.table.valuesRowThreeTitle')}
                            </strong>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-2 offset-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--left">
                            {I18n.t('routes.retail.sections.features.table.headers.one')}
                          </div>
                        </div>
                        <div className="col-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                            {I18n.t('routes.retail.sections.features.table.headers.two')}
                          </div>
                        </div>
                        <div className="col-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                            {I18n.t('routes.retail.sections.features.table.headers.three')}
                          </div>
                        </div>
                        <div className="col-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header">
                            {I18n.t('routes.retail.sections.features.table.headers.four')}
                          </div>
                        </div>
                        <div className="col-2 text-center p-0">
                          <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--right">
                            {I18n.t('routes.retail.sections.features.table.headers.five')}
                          </div>
                        </div>
                      </div>
                      {tableItemsThree && Object.keys(tableItemsThree).map((itemThree, index) => (
                        <div key={index.toString()}>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-2 p-0">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsThree[itemThree].value}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsThree[itemThree].valueOne}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsThree[itemThree].valueTwo}
                                  </div>
                                </div>
                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsThree[itemThree].valueThree}
                                  </div>
                                </div>

                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsThree[itemThree].valueFour}
                                  </div>
                                </div>

                                <div className="col-2 p-0 text-center">
                                  <div className="page-section__altorithm-table__item">
                                    {tableItemsThree[itemThree].valueFive}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <img
                    className="page-section__product-feature__image image-fluid"
                    src={imageUrl}
                    alt="product"
                  />
                )}
              </div>
              <div className="col-lg-6">
                <div className="page-section__product-feature__content">
                  {number && (
                    <div className="page-section__product-feature__content__number">
                      {number}
                    </div>
                  )}
                  <div className="page-section__product-feature__content__title">
                    {title}
                  </div>
                  <div className="page-section__product-feature__content__description">
                    {description}
                  </div>
                  {items && (
                    <div className={`page-section__product-feature__content__items ${isInline ? 'page-section__product-feature__content__items--inline' : ''}`}>
                      {items && Object.keys(items).map((item, index) => (
                        <div
                          className={`page-section__product-feature__content__items__single ${isInline ? 'page-section__product-feature__content__items__single--inline' : ''}`}
                          key={index.toString()}
                        >
                          <span className="page-section__product-feature__content__items__single__circle" />
                          <span className="page-section__product-feature__content__items__single__text">
                            {items[item]}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  {button && false && (
                    <div className="page-section__product-feature__content__button">
                      <LargeButton
                        text={I18n.t('shared.sections.products.productFeature.seeMoreButtonText')}
                        icon={<ArrowRight />}
                        href={button}
                        smaller
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}

export default ProductFeatureSection;
