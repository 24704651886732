import React from 'react';
import {
  Layout,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import PageHeader from '../../components/shared/PageHeader';
import ProductsSection from '../../components/shared/ProductsSection';
import ContactCTASection from '../../components/shared/ContactCTASection';
import FooterSection from '../../components/shared/FooterSection';
import ProductFeatureSection from '../../components/shared/ProductFeatureSection';
import SectionTitle from '../../components/shared/SectionTitle';
import ProductFeatureSectionSellSide
  from '../../components/shared/ProductFeatureSectionSellSide/ProductFeatureSectionSellSide';
import ProductFeatureSectionSellSideGateway from '../../components/shared/ProductFeatureSectionSellSideGateway';

class SellSide extends React.PureComponent {
  render() {
    const items = I18n.t('routes.sellside.sections.products');
    const { Content } = Layout;
    return (
      <Content className="sellside__container">
        <PageHeader
          title={I18n.t('routes.sellside.header.title')}
          subtitle={I18n.t('routes.sellside.header.subtitle')}
          images={[
            '/assets/img/sliders/sellside/img_sliders_sellside_bg_1.jpg',
          ]}
        />
        <div className="page-section pb-0">
          <SectionTitle
            headline={I18n.t('routes.sellside.sections.title')}
            description={I18n.t('routes.sellside.sections.subtitle')}
          />

          <ProductFeatureSectionSellSide />

          {items && Object.keys(items).map((item, index) => (
            <ProductFeatureSection
              key={index.toString()}
              imageUrl={items[item].imageUrl}
              title={items[item].title}
              number={items[item].number}
              description={items[item].description}
              reverse={items[item].reverse}
              gray={!!items[item].gray}
              items={items[item].items}
              button={items[item].button.url}
              group={[1, 2]}
            />
          ))}

          <ProductFeatureSectionSellSideGateway />
        </div>
        <ProductsSection gray />
        <ContactCTASection />
        <FooterSection />
      </Content>
    );
  }
}

export default SellSide;
