import Axios from 'axios';
import ApiUtil, { handleAxiosError } from '../utils/api';
import { axiosHandleUnatorizedErr } from '../../index';

function getInstance() {
  const axiosInstance = Axios.create({
    baseURL: ApiUtil.getBaseUrl(),
    timeout: 15000,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.interceptors.response.use((response) => response,
    async (err) => {
      if (err.response.status === 401) {
        axiosHandleUnatorizedErr();
      }
      return Promise.reject(handleAxiosError(err));
    },
  );

  return axiosInstance;
}

export default getInstance;
