import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class ProductFeatureSectionSellSide extends React.PureComponent {
  render() {
    const {
      gray,
      reverse,
    } = this.props;

    return (
      <section className={`page-section page-section__product-feature__sell-side ${gray ? 'page-section--gray' : ''}`}>
        <ScrollAnimation animateIn="fadeIn">
          <div className="container">
            <div className={`row row-eq-height ${reverse ? 'd-flex flex-row-reverse' : ''}`}>
              <div className="col-lg-6">
                <div className="page-section__product-feature__sell-side__content text-center">
                  <div className="page-section__product-feature__sell-side__content__title">
                    Plataforma RoboTrader
                  </div>
                  <div className="page-section__product-feature__sell-side__content__description">
                    Plataforma de ultrabaixa latência e máxima estabilidade, especializada em electronic & algorithmic
                    trading. A mais moderna e completa ferramenta do mercado, garantindo aos usuários os mais avançados
                    recursos para efetuarem suas operações com precisão, rapidez e segurança.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="page-section__product-feature__sell-side__content  text-center">
                  <div className="page-section__product-feature__sell-side__content__title">
                    RoboTrader Advanced
                  </div>
                  <div className="page-section__product-feature__sell-side__content__description">
                    Software de consulta e negociação eletrônica focado nos mercados financeiros e dispõe de um conjunto
                    de serviços com grande poder analítico e avançados recursos que visam estruturar, de maneira ágil e
                    confiável, as principais operações financeiras, como Trocas de Spread, Volatilidade, OTD (Over the
                    Day) para os segmentos Bovespa e BM&F.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}

export default ProductFeatureSectionSellSide;
