import React from 'react';
import {
  Layout, message, Popover, Icon
} from 'antd';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
// import GoogleMapReact from 'google-map-react';
import { SendOutlined } from '@ant-design/icons';
import ScrollAnimation from 'react-animate-on-scroll';

import PageHeader from '../../components/shared/PageHeader';
import FooterSection from '../../components/shared/FooterSection';
import ContactIconPhone from '../../app/svg/ic_contact_phone.svg';
import ContactIconSupport from '../../app/svg/ic_contact_support.svg';
import ContactLeftImage from '../../app/svg/img_contact_left.svg';
import ContactFormIcon from '../../app/svg/ic_contact_form.svg';
import LargeButton from '../../components/shared/LargeButton';

import AdvancedInput from '../../components/shared/AdvancedInput';
import AdvancedSelect from '../../components/shared/AdvancedSelect/AdvancedSelect';
import { getOptionsFromI18n } from '../../app/utils/string';
import { ContactActions } from '../../app/redux/actions';
import AdvancedMaskedInput from '../../components/shared/AdvancedMaskedInput';
import AdvancedButton from '../../components/shared/AdvancedButton';
import { LoadingSelectors } from '../../app/redux/reducers';
import AdvancedCheckbox from '../../components/shared/AdvancedCheckbox';

const { Content } = Layout;

const initialState = {
  name: null,
  lastName: '',
  email: '',
  phone: '',
  referal: '',
  isReferalOther: false,
  referalOther: '',
  subject: '',
  subjectOther: '',
  isSubjectOther: false,
  message: '',
  salesEmailVisible: true,
  supportEmailVisible: true,
  acceptedTerms: false,
  optin: false,
};

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    if (window.location.href.indexOf('obrigado') > 0) {
      message.success('Mensagem enviada com sucesso.');
    }
  }

  fieldChange(name, value) {
    const { state } = this;
    state[name] = value ? value.toString() : null;
    if(name === 'referal') {
      state['isReferalOther'] = (state[name] === I18n.t('routes.contact.sections.form.inputs.five.options.5.id'));
    }
    if(name === 'subject') {
      state['isSubjectOther'] = (state[name] === I18n.t('routes.contact.sections.form.inputs.four.options.7.id') || state[name] === I18n.t('routes.contact.sections.form.inputs.four.options.4.id'));
    }
    this.setState(state);
  }

  formSubmit(e) {
    e.preventDefault();

    const { sendMessage } = this.props;
    const form = this.state;

    const request = {
      name: form.name,
      lastName: form.lastName,
      email: form.email,
      phone: form.phone,
      from: (form.referal === I18n.t('routes.contact.sections.form.inputs.five.options.5.id') ? form.referalOther : form.referal),
      subject: (form.subject === I18n.t('routes.contact.sections.form.inputs.four.options.7.id') || form.subject === I18n.t('routes.contact.sections.form.inputs.four.options.4.id') ? form.subjectOther : form.subject),
      message: form.message,
      optin: form.optin
    };

    if (!request.name) {
      return message.error('O campo "Nome" é requerido. Verifique e tente novamente.',5);
    }

    if (!request.lastName) {
      return message.error('O campo "Sobrenome" é requerido. Verifique e tente novamente.',5);
    }

    if (!request.email) {
      return message.error('O campo "E-mail" é requerido. Verifique e tente novamente.',5);
    }

    if (!request.phone) {
      return message.error('O campo "Telefone comercial" é requerido. Verifique e tente novamente.',5);
    }

    if (!request.from) {
      return message.error('O campo "Como conheceu a BLK?" é requerido. Verifique e tente novamente.',5);
    }

    if (!request.subject) {
      return message.error('O campo "Assunto" é requerido. Verifique e tente novamente.',5);
    }

    if (!request.message) {
      return message.error('O campo "Mensagem" é requerido. Verifique e tente novamente.',5);
    }

    sendMessage(request, () => {
      this.setState(initialState, () => {
        this.fieldChange('name', '');
      });
      message.success('Mensagem enviada com sucesso. Em breve estaremos entrando em contato.',5);
    });
  }

  toggleSalesEmail() {
    this.setState({ salesEmailVisible: true });
  }

  toggleSupportEmail() {
    this.setState({ supportEmailVisible: true });
  }

  toggleAcceptedTerms(acceptedTerms) {
    this.setState({ acceptedTerms });
  }

  render() {
    const { salesEmailVisible, supportEmailVisible, acceptedTerms } = this.state;
    const { loading } = this.props;
    const isReferalOther = this.state.isReferalOther;
    const isSubjectOther = this.state.isSubjectOther;

    return (
      <Content className="contact__container">
        <PageHeader
          title={I18n.t('routes.contact.header.title')}
          subtitle={I18n.t('routes.contact.header.subtitle')}
          images={[
            'assets/img/sliders/contact/img_sliders_contact_bg_1.jpg',
          ]}
          hideButton
          smaller
          paddingBottom="45px"
        />
        <div className="container page-section--top-infos">
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <ScrollAnimation
                animateIn="fadeInUp"
                duration={0.3}
              >
                <div className="contact__info text-center">
                  <div className="contact__info__icon">
                    <img
                      src={ContactIconPhone}
                      alt="icon"
                    />
                  </div>
                  <div className="contact__info__title">
                    {I18n.t('routes.contact.sections.infos.one.title')}
                  </div>
                  <div className="contact__info__subtitle">
                    {I18n.t('routes.contact.sections.infos.one.subtitle')}
                  </div>
                  <p className="contact__info__description">
                    {I18n.t('routes.contact.sections.infos.one.description')}
                  </p>
                  <p className="contact__info__info contact__info__info--one">
                    <a href={`tel:${I18n.t('routes.contact.sections.infos.one.infoOneClean')}`}>
                      {I18n.t('routes.contact.sections.infos.one.infoOne')}
                    </a>
                  </p>
                  <p className="contact__info__info contact__info__info--two text-center">
                    {salesEmailVisible ? (
                      <a href={`mailto:${I18n.t('routes.contact.sections.infos.one.infoTwo')}`}>
                        {I18n.t('routes.contact.sections.infos.one.infoTwo')}
                      </a>
                    ) : (
                      <AdvancedButton
                        customClass="contact__info__info__button"
                        type="link"
                        text="Exibir e-mail de contato"
                        onClick={() => this.toggleSalesEmail()}
                      />
                    )}
                  </p>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-3 offset-lg-2">
              <ScrollAnimation
                animateIn="fadeInUp"
                delay={150}
                duration={0.3}
              >
                <div className="contact__info text-center">
                  <div className="contact__info__icon">
                    <img
                      src={ContactIconSupport}
                      alt="icon"
                    />
                  </div>
                  <div className="contact__info__title">
                    {I18n.t('routes.contact.sections.infos.two.title')}
                  </div>
                  <div className="contact__info__subtitle">
                    {I18n.t('routes.contact.sections.infos.two.subtitle')}
                  </div>
                  <p className="contact__info__description">
                    {I18n.t('routes.contact.sections.infos.two.description')}
                  </p>
                  <p className="contact__info__info contact__info__info--one">
                    <a href={`tel:${I18n.t('routes.contact.sections.infos.two.infoOneClean')}`}>
                      {I18n.t('routes.contact.sections.infos.two.infoOne')}
                    </a>
                  </p>
                  <div className="contact__info__info contact__info__info--two">

                    <div className="contact__info__info contact__info__info--two text-center">
                      {supportEmailVisible ? (
                        <a href={`mailto:${I18n.t('routes.contact.sections.infos.two.infoTwo')}`}>
                          {I18n.t('routes.contact.sections.infos.two.infoTwo')}
                        </a>
                      ) : (
                        <AdvancedButton
                          customClass="contact__info__info__button"
                          type="link"
                          text="Exibir e-mail de contato"
                          onClick={() => this.toggleSupportEmail()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>

        <div className="page-section page-section--gray page-section--contact-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={0.3}
                >
                  <div className="page-section--contact-form__left-img">
                    <img
                      className="img-fluid"
                      src={ContactLeftImage}
                      alt="contact"
                    />
                  </div>
                  <div className="page-section--contact-form__address">
                    <div className="page-section--contact-form__address__title">
                      {I18n.t('routes.contact.sections.form.address.title')}
                    </div>
                    <div className="page-section--contact-form__address__info">
                      {I18n.t('routes.contact.sections.form.address.info')}
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4 offset-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={0.3}
                >
                  <div className="page-section--contact-form__form">
                    <div className="page-section--contact-form__form__icon">
                      <img
                        src={ContactFormIcon}
                        alt="contact icon"
                      />
                    </div>
                    <div className="page-section--contact-form__form__title">
                      {I18n.t('routes.contact.sections.form.title')}
                    </div>

                    <form onSubmit={(e) => this.formSubmit(e)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <AdvancedInput
                            label={I18n.t('routes.contact.sections.form.inputs.one.label')}
                            placeholder={I18n.t('routes.contact.sections.form.inputs.one.placeholder')}
                            onChange={(val) => this.fieldChange('name', val)}
                            value={this.state.name}
                          />
                        </div>

                        <div className="col-lg-6">
                          <AdvancedInput
                            label={I18n.t('routes.contact.sections.form.inputs.seven.label')}
                            placeholder={I18n.t('routes.contact.sections.form.inputs.seven.placeholder')}
                            onChange={(val) => this.fieldChange('lastName', val)}
                            value={this.state.lastName}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <AdvancedInput
                            label={I18n.t('routes.contact.sections.form.inputs.two.label')}
                            placeholder={I18n.t('routes.contact.sections.form.inputs.two.placeholder')}
                            onChange={(val) => this.fieldChange('email', val)}
                            value={this.state.email}
                            type="email"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <AdvancedMaskedInput
                            label={I18n.t('routes.contact.sections.form.inputs.three.label')}
                            placeholder={I18n.t('routes.contact.sections.form.inputs.three.placeholder')}
                            mask={I18n.t('routes.contact.sections.form.inputs.three.mask')}
                            onChange={(val) => this.fieldChange('phone', val)}
                            value={this.state.phone}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 mb-3">
                          <AdvancedSelect
                            label={I18n.t('routes.contact.sections.form.inputs.five.label')}
                            placeholder={I18n.t('routes.contact.sections.form.inputs.five.placeholder')}
                            onChange={(val) => this.fieldChange('referal', val)}
                            value={this.state.referal}
                            options={getOptionsFromI18n(I18n.t('routes.contact.sections.form.inputs.five.options'))}
                          />
                        </div>
                      </div>

                      { isReferalOther &&
                        <div className="row">
                          <div className="col-11 offset-1 mb-3">
                            <AdvancedInput
                                label={I18n.t('routes.contact.sections.form.inputs.eight.label')}
                                placeholder={I18n.t('routes.contact.sections.form.inputs.eight.placeholder')}
                                onChange={(val) => this.fieldChange('referalOther', val)}
                                value={this.state.referalOther}
                                type="text"
                              />
                          </div>
                        </div>
                      }

                      <div className="row">
                        <div className="col-12 mb-3">
                          <AdvancedSelect
                            label={I18n.t('routes.contact.sections.form.inputs.four.label')}
                            placeholder={I18n.t('routes.contact.sections.form.inputs.four.placeholder')}
                            onChange={(val) => this.fieldChange('subject', val)}
                            value={this.state.subject}
                            options={getOptionsFromI18n(I18n.t('routes.contact.sections.form.inputs.four.options'))}
                          />
                        </div>
                      </div>

                      { isSubjectOther &&
                      <div className="row">
                        <div className="col-11 offset-1 mb-3">
                        <AdvancedInput
                                label={I18n.t('routes.contact.sections.form.inputs.nine.label')}
                                placeholder={I18n.t('routes.contact.sections.form.inputs.nine.placeholder')}
                                onChange={(val) => this.fieldChange('subjectOther', val)}
                                value={this.state.subjectOther}
                                type="text"
                              />
                        </div>
                      </div>
                      }

                      <div className="row">
                        <div className="col-12">
                          <AdvancedInput
                            label={I18n.t('routes.contact.sections.form.inputs.six.label')}
                            placeholder={I18n.t('routes.contact.sections.form.inputs.six.placeholder')}
                            onChange={(val) => this.fieldChange('message', val)}
                            value={this.state.message}
                            isTextarea
                          />
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-12">
                          <div className="page-section--contact-form__form__subtitle">
                            <AdvancedCheckbox
                              label={<Translate value="routes.contact.sections.form.acceptedTerms" dangerousHTML linkPrivacy={I18n.t('navbar.menuItems.privacy.url')} textPrivacy={I18n.t('navbar.menuItems.privacy.name')} />}
                              onChange={(val) => this.toggleAcceptedTerms(val)}
                              value={acceptedTerms}
                            />
                          </div>
                          <div className="page-section--contact-form__form__subtitle">
                            <div class="row">
                            <div class="col-10">
                              <AdvancedCheckbox
                                  label={I18n.t('routes.contact.sections.form.optIn')}
                                  onChange={(val) => this.fieldChange('optin', val)}
                                  value={this.state.optin}
                                />
                            </div>
                            <div class="col-2">
                              <Popover content={I18n.t('routes.contact.sections.form.optInInfo')} title="" overlayStyle={{maxWidth: '250px', marginRight: '-30px'}} trigger="hover">
                                <Icon type="info-circle" />
                              </Popover>
                            </div>
                            </div>
                          </div>
                          <div className="page-section--contact-form__form__button">
                            <LargeButton
                              text={I18n.t('routes.contact.sections.form.sendBtnText')}
                              icon={<SendOutlined />}
                              type="submit"
                              disabled={loading > 0 || !acceptedTerms}
                              loading={loading > 0}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
        <FooterSection />
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (data, callback) => dispatch(ContactActions.sendMessage(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contact);
