import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Link } from '@reach/router';
import NavbarLogo from '../../../app/svg/ic_navbar_logo.svg';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: false,
    };
  }

  isCurrentRoute(route) {
    return window.location.pathname === route;
  }

  toggleMenuVisiblity() {
    const { menuVisible } = this.state;
    this.setState({ menuVisible: !menuVisible });
  }

  render() {
    const { menuVisible } = this.state;
    return (
      <div className="navbar__container">
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <nav className={`navbar navbar-expand-lg navbar-dark ${!menuVisible ? '' : 'navbar__large'}`}>
                <button
                  className="navbar-toggler navbar__toggler"
                  type="button"
                  onClick={() => this.toggleMenuVisiblity()}
                >
                  {!menuVisible ? <MenuOutlined className="navbar__toggler__icon" /> : <CloseOutlined className="navbar__toggler__icon" />}
                </button>
                <Link
                  className="navbar-brand"
                  to={I18n.t('routes.home.url')}
                >
                  <img
                    src={NavbarLogo}
                    alt="blk logo"
                  />
                </Link>
                <div
                  className={`navbar-collapse ${!menuVisible ? 'collapse' : ''}`}
                  id="nav-content"
                >
                  <ul className="navbar-nav ml-auto navbar__menu">
                    <li className="nav-item navbar__menu__item">
                      <Link
                        to={I18n.t('routes.home.url')}
                        className={`nav-link navbar__menu__item__link hvr-underline-reveal ${this.isCurrentRoute(I18n.t('routes.home.url')) ? 'navbar__menu__item__link--active' : ''}`}
                      >
                        {I18n.t('navbar.menuItems.home.name')}
                      </Link>
                    </li>
                    <li className="nav-item navbar__menu__item">
                      <Link
                        to={I18n.t('routes.about.url')}
                        className={`nav-link navbar__menu__item__link hvr-underline-reveal ${this.isCurrentRoute(I18n.t('routes.about.url')) ? 'navbar__menu__item__link--active' : ''}`}
                      >
                        {I18n.t('navbar.menuItems.about.name')}
                      </Link>
                    </li>

                    <li className="nav-item navbar__menu__item">
                      <Link
                        to={I18n.t('routes.products.robotrader.url')}
                        className={`nav-link navbar__menu__item__link hvr-underline-reveal ${this.isCurrentRoute(I18n.t('routes.products.robotrader.url')) ? 'navbar__menu__item__link--active' : ''}`}
                      >
                        {I18n.t('navbar.menuItems.products.name')}
                      </Link>
                    </li>

                    <li className="nav-item navbar__menu__item">
                      <Link
                        to={I18n.t('routes.products.robotrader.algorithms.url')}
                        className={`nav-link navbar__menu__item__link hvr-underline-reveal ${this.isCurrentRoute(I18n.t('routes.products.robotrader.algorithms.url')) ? 'navbar__menu__item__link--active' : ''}`}
                      >
                        {I18n.t('navbar.menuItems.algorithms.name')}
                      </Link>
                    </li>

                    <li className="nav-item navbar__menu__item">
                      <Link
                        to={I18n.t('routes.contact.url')}
                        className={`nav-link navbar__menu__item__link hvr-underline-reveal ${this.isCurrentRoute(I18n.t('routes.contact.url')) ? 'navbar__menu__item__link--active' : ''}`}
                      >
                        {I18n.t('navbar.menuItems.support.name')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
export default Navbar;
