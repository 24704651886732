import React from 'react';
import {
  Layout,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import PageHeader from '../../components/shared/PageHeader';
import SectionCategory from '../../components/shared/sectionCategory';
import SectionStrategy from '../../components/shared/sectionStrategy';
import ProductsSection from '../../components/shared/ProductsSection';
import ContactCTASection from '../../components/shared/ContactCTASection';
import FooterSection from '../../components/shared/FooterSection';
// import AlertModal from '../../components/shared/AlertModal';

class Home extends React.PureComponent {
  render() {
    const { Content } = Layout;
    return (
      <Content className="home__container">
        {/* <AlertModal /> */}
        <PageHeader
          title={I18n.t('routes.home.header.title')}
          subtitle={I18n.t('routes.home.header.subtitle')}
          images={[
            'assets/img/sliders/home/img_sliders_home_bg_1.jpg',
            'assets/img/sliders/home/img_sliders_home_bg_2.jpg',
          ]}
        />
        <SectionCategory />
        <SectionStrategy gray />
        <ProductsSection />
        <ContactCTASection />
        <FooterSection />
      </Content>
    );
  }
}

export default Home;
