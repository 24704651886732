import React from 'react';
import { Link } from '@reach/router';
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TwitterSquareFilled,
} from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';
import BLKFooterLogo from '../../../app/svg/ic_logo_footer.svg';

class FooterSection extends React.PureComponent {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn">
        <div className="footer-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div className="footer-section__logo">
                  <img
                    src={BLKFooterLogo}
                    alt="footer logo"
                  />
                  <small className="footer-section__logo__text">
                    {I18n.t('shared.sections.footer.bottomLogoPhrase')}
                    <br />
                    <br />
                    <a
                      href="https://ri.b3.com.br/b3/empresas-do-grupo"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {I18n.t('shared.sections.footer.otherCompaniesLink')}
                    </a>
                  </small>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-section__menu">


                  <div className="footer-section__menu__item">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="footer-section__menu__title">
                          {I18n.t('shared.sections.footer.menuTitle')}
                        </div>
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <Link
                            className="footer-section__menu__item__single__link"
                            to={I18n.t('routes.home.url')}
                          >
                            {I18n.t('navbar.menuItems.home.name')}
                          </Link>
                        </div>
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <Link
                            className="footer-section__menu__item__single__link"
                            to={I18n.t('routes.about.url')}
                          >
                            {I18n.t('navbar.menuItems.about.name')}
                          </Link>
                        </div>
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <Link
                            className="footer-section__menu__item__single__link"
                            to={I18n.t('routes.products.robotrader.url')}
                          >
                            {I18n.t('navbar.menuItems.products.name')}
                          </Link>
                        </div>
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <Link
                            className="footer-section__menu__item__single__link"
                            to={I18n.t('routes.products.robotrader.algorithms.url')}
                          >
                            {I18n.t('navbar.menuItems.algorithms.name')}
                          </Link>
                        </div>
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <Link
                            className="footer-section__menu__item__single__link"
                            to={I18n.t('routes.contact.url')}
                          >
                            {I18n.t('navbar.menuItems.support.name')}
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="footer-section__menu__title">
                          Privacidade
                        </div>
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <a
                            className="footer-section__menu__item__single__link"
                            href={I18n.t('navbar.menuItems.privacy.url')}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {I18n.t('navbar.menuItems.privacy.name')}
                          </a>
                        </div>
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <a
                            className="footer-section__menu__item__single__link"
                            href={I18n.t('navbar.menuItems.cookiesPolicy.url')}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {I18n.t('navbar.menuItems.cookiesPolicy.name')}
                          </a>
                        </div>
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <a
                            className="footer-section__menu__item__single__link"
                            href={I18n.t('navbar.menuItems.terms.url')}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {I18n.t('navbar.menuItems.terms.name')}
                          </a>
                        </div>

                        <div className="footer-section__menu__item__single footer-section__menu__item__single--bordered">
                          <a
                            className="footer-section__menu__item__single__link"
                            href={I18n.t('navbar.menuItems.holderRights.url')}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {I18n.t('navbar.menuItems.holderRights.name')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-section__menu">
                  <div className="footer-section__menu__title">
                    Telefone
                  </div>

                  <div className="footer-section__menu__item">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="footer-section__menu__item__single footer-section__menu__item__single--phone">
                          Comercial: (11) 3295-1522
                          <br />
                          <br />
                          Suporte ao cliente: (11) 3295-1520
                          <br />
                        </div>
                        <div className="footer-section__menu__item__single__social-link">
                          <a
                            href="https://www.linkedin.com/company/blk-b3/"
                            class="footer-section__menu__item__single__social-link__single"
                            target="_blank"
                            rel="noopener"
                          >
                            <LinkedinFilled className="footer-section__menu__item__single__social-link__single__icon" />
                          </a>
                          {/* <Link
                            className="footer-section__menu__item__single__social-link__single"
                            to="/"
                          >
                            <TwitterSquareFilled className="footer-section__menu__item__single__social-link__single__icon" />
                          </Link>
                          <Link
                            className="footer-section__menu__item__single__social-link__single"
                            to="/"
                          >
                            <FacebookFilled className="footer-section__menu__item__single__social-link__single__icon" />
                          </Link>
                          <Link
                            className="footer-section__menu__item__single__social-link__single"
                            to="/"
                          >
                            <InstagramFilled className="footer-section__menu__item__single__social-link__single__icon" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    );
  }
}

export default FooterSection;
