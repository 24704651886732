import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class ProductFeatureSectionSellSideGateway extends React.PureComponent {
  render() {
    const {
      gray,
      reverse,
    } = this.props;

    return (
      <section className={`page-section page-section__product-feature__sell-side ${gray ? 'page-section--gray' : ''}`}>

        <ScrollAnimation animateIn="fadeIn">
          <div className="container">
            <div className={`row row-eq-height ${reverse ? 'd-flex flex-row-reverse' : ''}`}>
              <div className="col-lg-4">
                <div className="page-section__product-feature__sell-side__content text-center">
                  <div className="page-section__product-feature__sell-side__content__title">
                    Gateway FIX DMA
                  </div>
                  <div className="page-section__product-feature__sell-side__content__description">
                    Envio de ordem simples através de outro sistema. Esta ordem será enviada diretamente para a Bolsa de
                    Valores, respeitando as regras de riscos e limites. Para cada ordem executada o cliente receberá
                    automaticamente o seu devido execution report.
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="page-section__product-feature__sell-side__content  text-center">
                  <div className="page-section__product-feature__sell-side__content__title">
                    Gateway FIX DSA
                  </div>
                  <div className="page-section__product-feature__sell-side__content__description">
                    Envio de ordem de algoritmo através de outro sistema. Esta ordem será enviada diretamente para a
                    Bolsa de Valores, respeitando as regras de riscos e limites. Para cada ordem executada o cliente receberá
                    automaticamente o seu devido execution report.
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="page-section__product-feature__sell-side__content  text-center">
                  <div className="page-section__product-feature__sell-side__content__title">
                    Gateway FIX CARE
                  </div>
                  <div className="page-section__product-feature__sell-side__content__description">
                    Envio de ordem simples através de outro sistema. Esta ordem aparecerá na tela de um ou mais
                    usuários do Sistema RoboTrader da Corretora e a partir dela poderá ser criada uma ordem simples ou um algoritmo.
                    Para cada ordem executada o cliente receberá automaticamente o seu devido execution report.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </section>
    );
  }
}

export default ProductFeatureSectionSellSideGateway;
