import React from 'react';
import { I18n } from 'react-redux-i18n';

class RobotraderFuncSection extends React.PureComponent {
  render() {
    const itemsOne = I18n.t('routes.products.robotrader.sections.robotraderFunc.one.items');
    const itemsTwo = I18n.t('routes.products.robotrader.sections.robotraderFunc.two.items');

    return (
      <section className={`page-section page-section__robotrader-func ${this.props.gray ? 'page-section--gray' : ''}`}>
        <div className="page-section__robotrader-func__wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="page-section__robotrader-func__title">
                  {I18n.t('routes.products.robotrader.sections.robotraderFunc.one.title')}
                </div>
                <div className="page-section__robotrader-func__separator" />
                <div className="page-section__robotrader-func__subtitle">
                  {I18n.t('routes.products.robotrader.sections.robotraderFunc.one.subtitle')}
                </div>
                <div className="page-section__robotrader-func__items">
                  <div className="row">
                    {itemsOne && Object.keys(itemsOne).map((item, index) => (
                      <div
                        className="col-lg-12"
                        key={index.toString()}
                      >
                        <div className="page-section__robotrader-func__items__single">
                          <span className="page-section__robotrader-func__items__single__circle" />
                          <span className="page-section__robotrader-func__items__single__desc">
                            {I18n.t(`routes.products.robotrader.sections.robotraderFunc.one.items.${item}`)}.
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="page-section__robotrader-func__title">
                  {I18n.t('routes.products.robotrader.sections.robotraderFunc.two.title')}
                </div>
                <div className="page-section__robotrader-func__separator" />
                <div className="page-section__robotrader-func__subtitle">
                  {I18n.t('routes.products.robotrader.sections.robotraderFunc.two.subtitle')}
                </div>
                <div className="page-section__robotrader-func__items">
                  <div className="row">
                    {itemsTwo && Object.keys(itemsTwo).map((item, index) => (
                      <div
                        className="col-lg-6"
                        key={index.toString()}
                      >
                        <div className="page-section__robotrader-func__items__single">
                          <span className="page-section__robotrader-func__items__single__circle" />
                          <span className="page-section__robotrader-func__items__single__desc">
                            {I18n.t(`routes.products.robotrader.sections.robotraderFunc.two.items.${item}`)}.
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RobotraderFuncSection;
