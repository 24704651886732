import React from 'react';
import moment from 'moment-timezone';
import AdvancedButton from '../AdvancedButton';
import { I18n } from 'react-redux-i18n';

class CookiesMessage extends React.Component {

  constructor(props) {
    const { acceptedCookies } = localStorage;

    super(props);
    this.state = {
      acceptedCookies,
      showCookiesMessage: false,
    };
  }

  componentDidMount() {
    const { acceptedCookies } = this.state;
    if (!acceptedCookies) {
      this.setState({ showCookiesMessage: true });
    }
  }

  toggleAcceptCookies() {
    this.setState({ showCookiesMessage: false });
    localStorage.setItem('acceptedCookies', moment().format());
  }

  render() {
    const { showCookiesMessage } = this.state;

    return (
      <div>
        {showCookiesMessage && (
          <section className="cookies-message">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="cookies-message__phrase">
                    <strong>
                      BLK e a privacidade de dados
                    </strong>
                    <p>
                      Ao continuar navegando em nosso site, você concorda com a nossa&nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={I18n.t('navbar.menuItems.privacy.url')}
                      >
                        {I18n.t('navbar.menuItems.privacy.name')}
                      </a>
                      ,<br className="mobile-hidden" /> bem como, com os&nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={I18n.t('navbar.menuItems.terms.url')}
                      >
                        {I18n.t('navbar.menuItems.terms.name')}
                      </a>.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="cookies-message__button">
                    <AdvancedButton
                      onClick={() => this.toggleAcceptCookies()}
                      customClass="cookies-message__button__inner"
                      text="Eu aceito"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default CookiesMessage;
