import React from 'react';
import { I18n } from 'react-redux-i18n';
import SectionTitle from '../SectionTitle';
import TableFeatureCheck from '../../../app/svg/ic_table_feature.svg';
import TableFeatureOff from '../../../app/svg/ic_table_feature_off.svg';

class AlgorithmTableSection extends React.PureComponent {
  render() {
    const itemsOne = I18n.t('routes.products.robotrader.algorithms.sections.table.data.values');
    const itemsTwo = I18n.t('routes.products.robotrader.algorithms.sections.table.data.valuesTwo');

    return (
      <section className={`page-section page-section__altorithm-table  ${this.props.gray ? 'page-section--gray' : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                headline={I18n.t('routes.products.robotrader.algorithms.sections.table.title')}
                description={I18n.t('routes.products.robotrader.algorithms.sections.table.subtitle')}
              />
            </div>
          </div>
          <div className="page-section__altorithm-table__wrapper">

            <div className="row">
              <div className="col-md-6">
                <div className="page-section__altorithm-table__content-wrapper">
                  <div className="row">
                    <div className="col-2 offset-8 text-center p-0">
                      <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--left">
                        {I18n.t('routes.products.robotrader.algorithms.sections.table.data.headers.one')}
                      </div>
                    </div>
                    <div className="col-2 text-center p-0">
                      <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--right">
                        {I18n.t('routes.products.robotrader.algorithms.sections.table.data.headers.two')}
                      </div>
                    </div>
                  </div>
                  <div className="page-section__altorithm-table__item__wrapper">
                    {itemsOne && Object.keys(itemsOne).map((item, index) => (
                      <div
                        className="row"
                        key={index.toString()}
                      >
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-8 p-0">
                              <div className="page-section__altorithm-table__item">
                                {itemsOne[item].value}
                              </div>
                            </div>
                            <div className="col-2 p-0 text-center">
                              <div className="page-section__altorithm-table__item">
                                {itemsOne[item].checkOne ? (
                                  <img
                                    src={TableFeatureCheck}
                                    alt="feature check one"
                                  />
                                ) : (
                                  <img
                                    src={TableFeatureOff}
                                    alt="feature off one"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-2 p-0 text-center">
                              <div className="page-section__altorithm-table__item">
                                {itemsOne[item].checkTwo ? (
                                  <img
                                    src={TableFeatureCheck}
                                    alt="feature check two"
                                  />
                                ) : (
                                  <img
                                    src={TableFeatureOff}
                                    alt="feature off two"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="page-section__altorithm-table__contract-message">
                    {I18n.t('routes.products.robotrader.algorithms.sections.table.contractMessage')}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="page-section__altorithm-table__content-wrapper">
                  <div className="row">
                    <div className="col-2 offset-8 text-center p-0">
                      <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--left">
                        {I18n.t('routes.products.robotrader.algorithms.sections.table.data.headers.one')}
                      </div>
                    </div>
                    <div className="col-2 text-center p-0">
                      <div className="page-section__altorithm-table__item page-section__altorithm-table__item__header page-section__altorithm-table__item__header--right">
                        {I18n.t('routes.products.robotrader.algorithms.sections.table.data.headers.two')}
                      </div>
                    </div>
                  </div>
                  <div className="page-section__altorithm-table__item__wrapper">
                    {itemsTwo && Object.keys(itemsTwo).map((item, index) => (
                      <div
                        className="row"
                        key={index.toString()}
                      >
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-8 p-0">
                              <div className="page-section__altorithm-table__item">
                                {itemsTwo[item].value}
                              </div>
                            </div>
                            <div className="col-2 p-0 text-center">
                              <div className="page-section__altorithm-table__item">
                                {itemsTwo[item].checkOne ? (
                                  <img
                                    src={TableFeatureCheck}
                                    alt="feature check one"
                                  />
                                ) : (
                                  <img
                                    src={TableFeatureOff}
                                    alt="feature off one"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-2 p-0 text-center">
                              <div className="page-section__altorithm-table__item">
                                {itemsTwo[item].checkTwo ? (
                                  <img
                                    src={TableFeatureCheck}
                                    alt="feature check two"
                                  />
                                ) : (
                                  <img
                                    src={TableFeatureOff}
                                    alt="feature off two"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AlgorithmTableSection;
