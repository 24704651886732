import axios from 'axios';
import getInstance from './config';
import Api from '../config/api.config';

export default class UserApi {
  static async sendMessage(data) {
    let final = null;

    axios({
      method: 'post',
      url: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
      data: {
        oid: '00D3i000000vP1X',
        retURL: 'http://blk.com.br/contato/obrigado',
        first_name: 'roberto tester',
        last_name: 'sobrenome',
        email: 'teste@email.com',
        mobile: '19992949555',
        '00N3i00000CJUHN': 'B3',
        '00N3i00000CJUHP': 'teste',
        description: 'msg grande de testes msg grande de testes msg grande de testes msg grande de testes msg grande de testes',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((response) => {
        console.log(response);
      });

    return final;
  }

  static async post(values) {
    const instance = await getInstance();
    const { data } = await instance.post('/SendContactMessage?code='+Api.token,values);
    return data;
  }
}
