import React from 'react';
import { I18n } from 'react-redux-i18n';
import SectionTitle from '../SectionTitle';
import ProductRobotraderOne from '../../../app/svg/ic_robotrader_about_one.svg';
import ProductRobotraderTwo from '../../../app/svg/ic_robotrader_about_two.svg';
import ProductRobotraderThree from '../../../app/svg/ic_robotrader_about_three.svg';
import CardWithIcon from '../CardWithIcon';
import ScrollAnimation from 'react-animate-on-scroll';

class RobotraderAboutSection extends React.PureComponent {
  render() {
    return (
      <section className={`page-section page-section__robotrader-about ${this.props.gray ? 'page-section--gray' : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                headline={I18n.t('routes.products.robotrader.sections.aboutSection.title')}
                description={I18n.t('routes.products.robotrader.sections.aboutSection.description')}
              />
            </div>
          </div>

          <div className="page-section__robotrader-about__wrapper">
            <div className="row">
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={ProductRobotraderOne}
                    description={I18n.t('routes.products.robotrader.sections.aboutSection.items.one')}
                    iconCircle
                  />
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={150}
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={ProductRobotraderTwo}
                    description={I18n.t('routes.products.robotrader.sections.aboutSection.items.two')}
                    iconCircle
                  />
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={300}
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={ProductRobotraderThree}
                    description={I18n.t('routes.products.robotrader.sections.aboutSection.items.three')}
                    iconCircle
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RobotraderAboutSection;
