import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Carousel } from 'antd';
import SectionTitle from '../SectionTitle';
import ProductRobotrader from './Products/Robotrader';
// import CarouselChangeButtons from '../CarouselChangeButtons';

class ProductsSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.slider = null;
  }

  handleChange() {
    const top = document.getElementsByClassName('page-section__products')[0].offsetTop;
    window.scrollTo({
      top: top + 150,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <section className={`page-section page-section__products ${this.props.gray ? 'page-section--gray' : ''}`}>
        <SectionTitle
          headline={I18n.t('shared.sections.products.title')}
          description={I18n.t('shared.sections.products.subtitle')}
        />
        <div className="page-section__products__carousel">
          <div className="mobile-visible page-section__products__carousel__mobile-change-buttons">
            {/* <CarouselChangeButtons
              handlePrev={() => {
                this.slider.prev();
                this.handleChange();
              }}
              handleNext={() => {
                this.slider.next();
                this.handleChange();
              }}
            /> */}
          </div>
          <Carousel
            ref={(slider) => (this.slider = slider)}
            swipeToSlide
            infinite
            dots
          >
            <ProductRobotrader />
          </Carousel>

          {/* <CarouselChangeButtons
            handlePrev={() => {
              this.slider.prev();
              this.handleChange();
            }}
            handleNext={() => {
              this.slider.next();
              this.handleChange();
            }}
          /> */}

        </div>
      </section>
    );
  }
}

export default ProductsSection;
