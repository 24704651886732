import React from 'react';
import InputMask from 'react-input-mask';

const AdvancedMaskedInput = (
  {
    label,
    onChange,
    value,
    disabled,
    mask,
    name,
  },
) => (
  <div className="advanced-masked-input">
    <label className="advanced-masked-input__label">
      <span className="advanced-masked-input__label__inner">{label}</span>
      <InputMask
        mask={mask}
        value={value}
        onChange={(ev) => onChange(ev.currentTarget.value)}
        disabled={disabled || false}
        alwaysShowMask
        name={name || undefined}
      >
        {(inputProps) => (
          <input
            {...inputProps}
            className="ant-input"
            value={value}
          />
        )}
      </InputMask>

    </label>
  </div>
);

export default AdvancedMaskedInput;
