import React from 'react';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';
import CardWithIcon from '../CardWithIcon';
import StrategyIconOne from '../../../app/svg/ic_strategy_one.svg';
import StrategyIconThree from '../../../app/svg/ic_strategy_three.svg';

class HomeSectionStrategy extends React.PureComponent {
  render() {
    return (
      <section className={`page-section page-section__strategy ${this.props.gray ? 'page-section--gray' : ''}`}>
        <div className="container">
          <div className="page-section__strategy__cards">
            <div className="row">
              <div className="col-lg-5 offset-lg-1">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={StrategyIconOne}
                    title={I18n.t('routes.home.sections.strategy.cards.one.title')}
                    subtitle={I18n.t('routes.home.sections.strategy.cards.one.subtitle')}
                    description={I18n.t('routes.home.sections.strategy.cards.one.description')}
                  />
                </ScrollAnimation>
              </div>
              <div className="col-lg-5">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={300}
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={StrategyIconThree}
                    title={I18n.t('routes.home.sections.strategy.cards.three.title')}
                    subtitle={I18n.t('routes.home.sections.strategy.cards.three.subtitle')}
                    description={(
                      <div className="page-section__strategy__cards__item">
                        <span /> {I18n.t('routes.home.sections.strategy.cards.three.descriptionOne')}
                        <span /> {I18n.t('routes.home.sections.strategy.cards.three.descriptionTwo')}
                        <span /> {I18n.t('routes.home.sections.strategy.cards.three.descriptionThree')}
                        <span /> {I18n.t('routes.home.sections.strategy.cards.three.descriptionFour')}
                        <span /> {I18n.t('routes.home.sections.strategy.cards.three.descriptionFive')}
                      </div>
                    )}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HomeSectionStrategy;
