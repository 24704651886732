import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class SectionTitle extends React.PureComponent {
  render() {
    const {
      headline,
      description,
    } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <div className="section-title__headline">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={0.3}
                >
                  {headline}
                </ScrollAnimation>
              </div>
              <ScrollAnimation
                animateIn="fadeInUp"
                delay={150}
                duration={0.3}
              >
                <div className="section-title__divider" />
              </ScrollAnimation>
              <div className="section-title__description">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={300}
                  duration={0.3}
                >
                  {description}
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionTitle;