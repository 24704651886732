import {
  addLoading,
  removeLoading,
} from './loading';
import ContactRequests from '../../api/contact';

export const sendMessage = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await ContactRequests.post(data);
    callback();
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(removeLoading());
  }
};
