import React from 'react';
import { Button } from 'antd';

class LargeButton extends React.PureComponent {
  render() {
    const {
      text,
      type,
      onClick,
      href,
      target,
      icon,
      smaller,
      blueBackground,
      loading,
      disabled,
    } = this.props;
    return (
      <Button
        className={`large-button ${smaller ? 'large-button--smaller' : ''} ${blueBackground ? 'large-button--blue' : ''}`}
        type={type || 'primary'}
        onClick={onClick ? () => onClick() : undefined}
        href={href || undefined}
        target={target || undefined}
        htmlType={type || 'button'}
        loading={!!loading}
        disabled={!!disabled}
      >
        {text}{icon && <span className="large-button__icon">{icon}</span>}
      </Button>
    );
  }
}

export default LargeButton;
