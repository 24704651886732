import { Router } from '@reach/router';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Public from './Public';
import Home from '../../containers/home';
import About from '../../containers/about';
import Robotrader from '../../containers/products/robotrader';
import RobotraderAlgorithms from '../../containers/products/robotrader/algorithms';
import Retail from '../../containers/retail';
import SellSide from '../../containers/sellSide';
import Contact from '../../containers/contact';
import BuySide from '../../containers/buySide';

class Content extends React.PureComponent {
  render() {
    return (
      <Router>
        <Public
          path={I18n.t('routes.home.url')}
          container={<Home />}
          title={`${I18n.t('routes.home.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.about.url')}
          container={<About />}
          title={`${I18n.t('routes.about.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.products.robotrader.url')}
          container={<Robotrader />}
          title={`${I18n.t('routes.products.robotrader.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.products.robotrader.algorithms.url')}
          container={<RobotraderAlgorithms />}
          title={`${I18n.t('routes.products.robotrader.algorithms.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.retail.url')}
          container={<Retail />}
          title={`${I18n.t('routes.retail.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.sellside.url')}
          container={<SellSide />}
          title={`${I18n.t('routes.sellside.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.buyside.url')}
          container={<BuySide />}
          title={`${I18n.t('routes.buyside.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.contact.url')}
          container={<Contact />}
          title={`${I18n.t('routes.contact.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={`${I18n.t('routes.contact.url')}${I18n.t('routes.contact.thanks')}`}
          container={<Contact />}
          title={`${I18n.t('routes.contact.pageTitle')} - ${I18n.t('application.name')}`}
        />
      </Router>
    );
  }
}

export default Content;
