import React from 'react';
import { I18n } from 'react-redux-i18n';
import { ArrowRight } from 'react-feather';
import LargeButton from '../LargeButton';

class CardWithIcon extends React.PureComponent {
  render() {
    const {
      icon,
      title,
      subtitle,
      description,
      link,
      left,
      iconCircle,
    } = this.props;
    return (
      <div className={`card-with-icon ${left ? 'text-left' : 'text-center'}`}>
        <div className={`card-with-icon__icon ${iconCircle ? 'card-with-icon__icon--circle' : ''}`}>
          <img
            src={icon}
            alt="icon"
          />
        </div>
        {title && (
          <div className="card-with-icon__title">
            {title}
          </div>
        )}
        {subtitle && (
          <div className="card-with-icon__subtitle">
            {subtitle}
          </div>
        )}
        <div className="card-with-icon__description">
          {description}
        </div>
        {link && (
          <div className="card-with-icon__button">
            <LargeButton
              text={I18n.t('routes.home.sections.category.cards.buttonText')}
              icon={<ArrowRight />}
              href={link}
              smaller
            />
          </div>
        )}
      </div>
    );
  }
}

export default CardWithIcon;