import React from 'react';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';
import CardWithIcon from '../CardWithIcon';
import CategoryIconOne from '../../../app/svg/ic_category_one.svg';
import CategoryIconTwo from '../../../app/svg/ic_category_two.svg';
import CategoryIconThree from '../../../app/svg/ic_category_three.svg';
import SectionTitle from '../SectionTitle';

class HomeSectionCategory extends React.PureComponent {
  render() {
    return (
      <section className={`page-section page-section__category ${this.props.gray ? 'page-section--gray' : ''}`}>
        <SectionTitle
          headline={I18n.t('routes.home.sections.category.headline')}
          description={I18n.t('routes.home.sections.category.description')}
        />
        <div className="container">
          <div className="page-section__category__cards">
            <div className="row">
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={CategoryIconOne}
                    title={I18n.t('routes.home.sections.category.cards.one.subtitle')}
                    description={I18n.t('routes.home.sections.category.cards.one.description')}
                    link={I18n.t('routes.retail.url')}
                  />
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={150}
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={CategoryIconTwo}
                    title={I18n.t('routes.home.sections.category.cards.two.subtitle')}
                    description={I18n.t('routes.home.sections.category.cards.two.description')}
                    link={I18n.t('routes.sellside.url')}
                  />
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={300}
                  duration={0.3}
                >
                  <CardWithIcon
                    icon={CategoryIconThree}
                    title={I18n.t('routes.home.sections.category.cards.three.subtitle')}
                    description={I18n.t('routes.home.sections.category.cards.three.description')}
                    link={I18n.t('routes.buyside.url')}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HomeSectionCategory;