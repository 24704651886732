import React from 'react';
import {
  Layout,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';

import PageHeader from '../../../components/shared/PageHeader';
import ContactCTASection from '../../../components/shared/ContactCTASection';
import FooterSection from '../../../components/shared/FooterSection';
import RobotraderAboutSection from '../../../components/shared/RobotraderAboutSection';
import RobotraderFeaturedFuncSection from '../../../components/shared/RobotraderFeaturedFuncSection';
import MarketResumeSection from '../../../components/shared/MarketResumeSection';
import RobotraderFuncSection from '../../../components/shared/RobotraderFuncSection';
import ProductFeatureSection from '../../../components/shared/ProductFeatureSection';
import HomeSectionCategory from '../../../components/shared/sectionCategory';
import SectionFeatureSlider from '../../../components/shared/sectionFeatureSlider';

class Robotrader extends React.PureComponent {
  render() {
    const { Content } = Layout;
    const items = I18n.t('shared.sections.products.productFeature.robotrader.items');

    return (
      <div>
        <Content className="robotrader__container">
          <PageHeader
            title={I18n.t('routes.products.robotrader.header.title')}
            subtitle={I18n.t('routes.products.robotrader.header.subtitle')}
            images={[
              '/assets/img/sliders/products/robotrader/img_sliders_robotrader_bg_1.jpg',
            ]}
          />
          <RobotraderAboutSection gray />
          <RobotraderFeaturedFuncSection />
        </Content>

        <ScrollAnimation animateIn="fadeIn">
          <SectionFeatureSlider />
        </ScrollAnimation>

        <Content className="robotrader__container">
          <MarketResumeSection />
          <ScrollAnimation animateIn="fadeIn">
            <RobotraderFuncSection />
          </ScrollAnimation>

          {items && Object.keys(items).map((item, index) => (
            <ScrollAnimation
              animateIn="fadeIn"
              key={index.toString()}
            >
              <ProductFeatureSection
                imageUrl={items[item].imageUrl}
                title={items[item].title}
                number={items[item].number}
                description={items[item].description}
                reverse={items[item].reverse}
                gray={!!items[item].gray}
                items={items[item].items}
              />
            </ScrollAnimation>
          ))}
          <HomeSectionCategory gray />
          <ContactCTASection />
          <FooterSection />
        </Content>
      </div>
    );
  }
}

export default Robotrader;
