import React from 'react';
import {
  Layout,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import ScrollAnimation from 'react-animate-on-scroll';

import PageHeader from '../../../../components/shared/PageHeader';
import ContactCTASection from '../../../../components/shared/ContactCTASection';
import FooterSection from '../../../../components/shared/FooterSection';
import AlgorithmTableSection from '../../../../components/shared/AlgorithmTableSection';
import ProductsSection from '../../../../components/shared/ProductsSection';
import ProductFeatureSection from '../../../../components/shared/ProductFeatureSection';

class RobotraderAlgorithms extends React.PureComponent {
  render() {
    const { Content } = Layout;
    const items = I18n.t('routes.products.robotrader.algorithms.sections.features');
    return (
      <Content className="robotrader__container">
        <PageHeader
          title={I18n.t('routes.products.robotrader.algorithms.header.title')}
          subtitle={I18n.t('routes.products.robotrader.algorithms.header.subtitle')}
          images={[
            '/assets/img/sliders/products/robotrader/img_sliders_robotrader_bg_1.jpg',
          ]}
        />
        <ScrollAnimation animateIn="fadeIn">
          <AlgorithmTableSection gray />
        </ScrollAnimation>
        {items && Object.keys(items).map((item, index) => (
          <ProductFeatureSection
            key={index.toString()}
            imageUrl={items[item].imageUrl}
            title={items[item].title}
            number={items[item].number}
            description={items[item].description}
            reverse={items[item].reverse}
            gray={!!items[item].gray}
            items={items[item].items}
          />
        ))}
        <ProductsSection />
        <ContactCTASection />
        <FooterSection />
      </Content>
    );
  }
}

export default RobotraderAlgorithms;
